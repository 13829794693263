import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import { VideoTag } from '@components/VideoTag';
import { isMobileAtom } from '@atoms/appSettings';
import { mediaTypeStyles as classes } from './styleObjects/mediaTypeStyles';
import { MediaSize, MediaColumnContent } from '@models/column';
import { PlayIcon } from '@components/IconComponents/PlayIcon';

interface MediaTypeProps {
    content: MediaColumnContent;
    lazyLoad?: boolean;
}

const getMediaGridSize = (size: MediaSize | undefined): number => {
    switch (size) {
        case 'small':
            return 7;
        case 'medium':
            return 9;
        default:
            return 12;
    }
};

export function MediaType({ content, lazyLoad }: MediaTypeProps): JSX.Element | null {
    const [isMobile] = useAtom(isMobileAtom);
    const { autoPlay, title } = content;
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    return content.src ? (
        <Grid container justifyContent='center'>
            <Grid item xs={getMediaGridSize(isMobile ? 'large' : content.mediaSize)}>
                {'gallery' == content.mediaSource ? (
                    <div css={classes.galleryVideoMedia}>
                        <VideoTag
                            key={`${autoPlay}`}
                            isMobile={isMobile}
                            videoPath={content.src}
                            mobileVideoPath={content.src}
                            autoPlay={autoPlay}
                            controls={!autoPlay}
                            lazyLoad={lazyLoad}
                            title={title || ''}
                            onPlay={() => setIsVideoPlaying(true)}
                            onPause={() => setIsVideoPlaying(false)}
                        />
                        {!autoPlay && !isVideoPlaying ? (
                            <PlayIcon data-testid='play-button' css={classes.playButton} />
                        ) : null}
                    </div>
                ) : (
                    <div css={classes.videoMedia}>
                        <iframe
                            data-testid='videoColumn'
                            title={title}
                            data-src={lazyLoad ? content.src : undefined}
                            src={!lazyLoad ? content.src : undefined}
                            frameBorder='0'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    ) : null;
}
