import type { StandardImageBannerProps } from '@components/SectionComponents/StandardImageBanner';
import { breakpoints, v2Colors, getRgbaColor } from '@web-for-marketing/react-ui';
import { ContentBoxBackgroundColor } from '@models/section/standardImageBannerSection';

const contentBoxColors: Record<ContentBoxBackgroundColor, string> = {
    [ContentBoxBackgroundColor.Blue]: getRgbaColor(v2Colors.background.dark, 0.85),
    [ContentBoxBackgroundColor.Black]: getRgbaColor('#000000', 0.6),
};

export const standardImageBannerStyle = {
    parentContainer: (displayContentBlock?: boolean) =>
        ({
            alignItems: 'flex-start',
            width: '100%',
            background: !displayContentBlock
                ? 'linear-gradient(rgba(1, 23, 41, 0.8), rgba(1, 23, 41, 0.8))'
                : undefined,
            minHeight: '40rem',
            position: 'relative',
            '@media (max-width: 1179.9px)': {
                minHeight: 0,
            },
        }) as const,
    noBottomPadding: {
        paddingBottom: '0px',
    },
    mainContainer: {
        zIndex: 1,
        '@media (max-width: 640px)': {
            margin: 0,
            '& > *': {
                marginLeft: 0,
                maxWidth: 'unset',
                width: '100%',
            },
        },
    },
    contentContainer: {
        '& > *': {
            marginBottom: '1rem',
            maxWidth: '60rem',
        },
        '& > h1': {
            marginBottom: '1rem',
        },
        '& > h2': {
            marginBottom: '1rem',
        },
        '& > *:last-child': {
            marginBottom: 0,
        },
    },
    headerBannerContainer: {
        paddingTop: '5rem',
        paddingBottom: '9rem',
        '@media (max-width: 640px)': {
            padding: '4rem 3.6rem 2rem !important',
        },
    },
    headerContentBlock: (color: ContentBoxBackgroundColor) =>
        ({
            backgroundColor: color ? contentBoxColors[color] : contentBoxColors[ContentBoxBackgroundColor.Blue],
            padding: '5rem 8rem !important',
        }) as const,
    officeHeaderContainer: {
        paddingBottom: '8rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingBottom: '4rem',
        },
    },
    listItems: {
        fontSize: '2rem',
        lineHeight: 1.4,
        marginBottom: '0.4em',
        color: '#FFFFFF',
        display: 'list-item',
        padding: 0,
        paddingLeft: '0.5rem',
    },
    descriptionTypography: {
        color: 'white',
        marginTop: '0rem',
        marginBottom: '2rem',

        '& p': {
            margin: '0',
        },
    },
    buttonItem: {
        height: '100%',
        marginBottom: '2rem !important',
        marginTop: '1rem',
        marginRight: '2rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
            marginRight: 0,
            width: '100%',
        },
    },
    lists: {
        listStyle: 'disc',
        paddingInlineStart: '2rem',
        marginBottom: '2rem',
        minWidth: '50%',
        '@media (max-width: 1179.9px)': {
            fontSize: '1.8rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.6rem',
        },
    },
    outlinedWhiteButton: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(255,255,255,0)',
        borderColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(1, 23, 41, 0.5)',
            borderColor: '#FFFFFF',
            color: '#FFFFFF',
        },
    },
    gradient: {
        zIndex: 1,
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        left: 0,
    },
    hideImage: {
        visibility: 'hidden',
    },
    setUnder: {
        zIndex: -1,
    },
    buttonContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& > div:last-child': {
                marginBottom: '0',
            },
        },
    },
    defaultBg: {
        color: '#fff',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    noGradientBg: (
        imageUrl: string,
        backgroundPosition: StandardImageBannerProps['backgroundPosition'],
        reducedMotion: boolean,
        parallax: StandardImageBannerProps['parallax']
    ) => ({
        backgroundImage: `url('${imageUrl}') !important`,
        backgroundPosition: backgroundPosition
            ? `${backgroundPosition.x}% ${backgroundPosition.y}% !important`
            : 'center !important',
        backgroundAttachment: !reducedMotion && parallax ? 'fixed !important' : 'scroll !important',
    }),
    gradientImageBg: (
        imageUrl: string,
        align: StandardImageBannerProps['align'],
        parallax: StandardImageBannerProps['parallax'],
        backgroundPosition: StandardImageBannerProps['backgroundPosition'],
        reducedMotion: boolean
    ) => {
        let gradientResult;
        if (align === 'flex-end') {
            gradientResult =
                'linear-gradient(90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0) 25%, rgba(1, 23, 41, 0.75) 50%, rgba(1, 23, 41, 1) 100%)';
        } else if (align === 'center') {
            gradientResult =
                'linear-gradient(90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0.5) 15%, rgba(1, 23, 41, .9) 40%,rgba(1, 23, 41, .9) 60%, rgba(1, 23, 41, 0.5) 85%, rgba(1, 23, 41, 0) 100%)';
        } else {
            gradientResult =
                'linear-gradient(-90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0) 25%, rgba(1, 23, 41, 0.75) 50%, rgba(1, 23, 41, 1) 100%)';
        }

        return {
            backgroundImage: `${gradientResult}, url('${imageUrl}') !important`,
            backgroundPosition: backgroundPosition
                ? `${backgroundPosition.x}% ${backgroundPosition.y}% !important`
                : 'center !important',
            backgroundAttachment: !reducedMotion && parallax ? 'fixed !important' : 'scroll !important',
        };
    },
    mobileGradientImageBg: (
        imageUrl: string,
        backgroundPosition: StandardImageBannerProps['backgroundPosition'],
        reducedMotion: boolean,
        parallax: StandardImageBannerProps['parallax']
    ) => ({
        backgroundImage: `linear-gradient(rgba(1, 23, 41, 0.8), rgba(1, 23, 41, 0.8)), url('${imageUrl}') !important`,
        backgroundPosition: backgroundPosition
            ? `${backgroundPosition.x}% ${backgroundPosition.y}% !important`
            : 'center !important',
        backgroundAttachment: !reducedMotion && parallax ? 'fixed !important' : 'scroll !important',
    }),
    mobileGradientBg: (color: ContentBoxBackgroundColor, isDisplayHeader: boolean) =>
        ({
            backgroundImage: isDisplayHeader
                ? undefined
                : 'linear-gradient(rgba(1, 23, 41, 0.8), rgba(1, 23, 41, 0.8))',
            backgroundColor: isDisplayHeader ? contentBoxColors[color] : undefined,
        }) as const,
    gradientBg: (align: StandardImageBannerProps['align']) => {
        let gradientResult;

        if (align === 'flex-end') {
            gradientResult =
                'linear-gradient(90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0) 25%, rgba(1, 23, 41, 0.75) 50%, rgba(1, 23, 41, 1) 100%)';
        } else if (align === 'center') {
            gradientResult =
                'linear-gradient(90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0.5) 15%, rgba(1, 23, 41, .9) 40%,rgba(1, 23, 41, .9) 60%, rgba(1, 23, 41, 0.5) 85%, rgba(1, 23, 41, 0) 100%)';
        } else {
            gradientResult =
                'linear-gradient(-90deg, rgba(1, 23, 41, 0) 0%, rgba(1, 23, 41, 0) 25%, rgba(1, 23, 41, 0.75) 50%, rgba(1, 23, 41, 1) 100%)';
        }

        return {
            backgroundImage: gradientResult,
        };
    },
    picturePosition: (backgroundPosition: StandardImageBannerProps['backgroundPosition']) => ({
        objectPosition: backgroundPosition ? `${backgroundPosition.x}% ${backgroundPosition.y}%` : undefined,
    }),
    autoMargin: {
        margin: 'auto',
    },
} as const;
