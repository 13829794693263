import { VideoType } from '@models/video';

export function getFullVideoLink(key, type) {
    if (!key) return;
    if (type === VideoType.YouTube) {
        return `https://www.youtube.com/watch?v=${key}`;
    } else if (type === VideoType.Vidyard) {
        return `https://play.vidyard.com/${key}`;
    }
}

export function getFullDriveShareLink(key) {
    return `https://drive.google.com/file/d/${key}/view?usp=sharing`;
}

export function getVideoThumbnailImage(key, type) {
    if (!key) return;
    if (type === VideoType.YouTube) {
        return `https://img.youtube.com/vi/${key}/maxresdefault.jpg`;
    } else if (type === VideoType.Vidyard) {
        return `https://play.vidyard.com/${key}.jpg`;
    }
}

export function getDriveDirectDownloadLink(key) {
    return `https://docs.google.com/uc?export=download&id=${key}`;
}

export function getYoutubeKey(url) {
    let youtubeKey = '';

    if (url.includes('youtube.com/') && url.includes('v=')) {
        youtubeKey = url.split('v=')[1].split('&')[0];
    } else if (url.includes('youtube.com/') && url.includes('embed/')) {
        youtubeKey = url.split('embed/')[1].split('?')[0];
    } else if (url.includes('youtu.be/')) {
        youtubeKey = url.split('.be/')[1];
    }

    return youtubeKey;
}

export function sanitizeToEmbedYoutubeUrl(url) {
    if (url.includes('youtube.com') && !url.includes('embed/')) {
        return `https://www.youtube.com/embed/${getYoutubeKey(url)}`;
    }
    return url;
}

export function getDriveKey(url) {
    let driveKey = '';

    if (url.includes('drive.google.com/')) {
        driveKey = url.split('d/')[1].split('/view')[0];
    }

    return driveKey;
}

export function getVidyardKey(url) {
    if (!url) return;
    let vidyardKey = '';
    const regexShare = /https:\/\/share\.vidyard\.com\/watch\/([^?]+)/i;
    const regexPlay = /https:\/\/play\.vidyard\.com\/([^?]+)/i;
    vidyardKey = url.match(regexShare) || url.match(regexPlay);

    if (vidyardKey && vidyardKey.length > 1) {
        return vidyardKey[1];
    }
    return vidyardKey;
}
