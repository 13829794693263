import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { scrollingBannerStyles as classes } from './styleObjects/ScrollingBanner';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay, virtualize, bindKeyboard, type SlideRenderProps } from 'react-swipeable-views-utils-react-18-fix';
import { mod } from 'react-swipeable-views-core-react-18-fix';
import { ScrollingBannerSlide } from './ScrollingBannerSlide';
import { ScrollingBannerContent } from '@models/section/scrollingBannerSection';
import { SectionAnimation } from '@models/section';

const EnhancedSwipeableViews = bindKeyboard(autoPlay(virtualize(SwipeableViews)));

type ScrollingBannerProps = ScrollingBannerContent & {
    animations: SectionAnimation;
    zIndex: number;
    lazyLoad: boolean;
};

export function ScrollingBanner({
    banners,
    settings,
    animations,
    zIndex,
    lazyLoad,
}: ScrollingBannerProps): JSX.Element {
    const reducedMotion = useRef(false);
    const sliderRef = useRef<HTMLDivElement>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    // eslint-disable-next-line react/no-multi-comp
    function slideRenderer({ index, key }: SlideRenderProps): React.ReactNode {
        return <ScrollingBannerSlide key={key} lazyLoad={lazyLoad} banner={banners[mod(index, banners.length)]} />;
    }

    function handleStepChange(step: number): void {
        setActiveSlideIndex(step);
    }

    useEffect(() => {
        if (sliderRef.current) {
            const activeSlide = sliderRef.current.querySelector('[data-swipeable][aria-hidden="false"]');
            if (activeSlide) {
                const activeSlideLink = activeSlide.querySelector('a');
                const activeSlideButton = activeSlide.querySelector('button');
                const activeSlideInteractiveElement = activeSlideLink || activeSlideButton;
                activeSlideInteractiveElement && activeSlideInteractiveElement.setAttribute('tabindex', '0');
                return () => {
                    activeSlideInteractiveElement && activeSlideInteractiveElement.setAttribute('tabindex', '-1');
                };
            }
        }
    }, [activeSlideIndex]);

    useEffect(() => {
        reducedMotion.current = window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches;
    }, []);

    return (
        <Grid
            container
            css={[classes.scroll, { padding: settings.spacing, zIndex: zIndex }]}
            className={`${animations.animationsEnabled && 'animateContainers'}`}
            ref={sliderRef}
        >
            <EnhancedSwipeableViews
                index={activeSlideIndex}
                onChangeIndex={handleStepChange}
                autoplay={settings.isAutoplay}
                interval={settings.autoplayDelay}
                animateTransitions={settings.isAnimated && !(reducedMotion && reducedMotion.current)}
                enableMouseEvents
                springConfig={{
                    duration: '2000ms',
                    easeFunction: 'ease',
                    delay: '0s',
                }}
                slideRenderer={slideRenderer}
                slideCount={!settings.isInfinite ? banners.length : undefined}
            />
        </Grid>
    );
}

ScrollingBanner.defaultProps = {
    settings: {},
    animations: {
        animationsEnabled: false,
    },
};
