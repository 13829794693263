import { breakpoints } from '@web-for-marketing/react-ui';

export const styles = {
    imageMedia: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
    },
    mediaContainer: {
        maxHeight: '40rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    contentContainer: {
        '&  > *': {
            marginBottom: '3rem',
        },
        '&  > *:last-child': {
            marginBottom: '0',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '&  > *': {
                marginBottom: '2rem',
            },
            '&  > *:last-child': {
                marginBottom: '0',
            },
        },
    },
    buttonItem: {
        display: 'flex',
        marginRight: '2rem',
        marginBottom: '2rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
            marginRight: 0,
            width: '100%',
            height: 'auto',
        },
    },
    icon: {
        height: '1.25em',
        width: '1.25em',
        verticalAlign: 'middle',
        display: 'inline',
        marginRight: '1rem',
    },
    infoContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '&  > *': {
                marginBottom: '2rem',
            },
            '&  > *:last-child': {
                marginBottom: '0',
            },
        },
    },
} as const;
