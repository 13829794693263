import { v2Colors, breakpoints } from '@web-for-marketing/react-ui';

export const imageTitleLinkCardsStyle = {
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        marginBottom: '3rem',
    },
    linkText: {
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: '1.5rem',
        },
    },
    bodyText: {
        paddingBottom: '9.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingBottom: '5.2rem',
        },
    },
    noButtonBody: {
        paddingBottom: '4rem',
    },
    galleryContainer: {
        width: '100%',
        padding: '0 !important',
    },
    verticalContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 3,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '4rem',
        },
    },
    tileContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexWrap: 'wrap',
            padding: 0,
        },
    },
    mobileDirection: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexDirection: 'row',
        },
    },
} as const;
