import React from 'react';
import { Grid } from '@mui/material';
import { v2Colors } from '@web-for-marketing/react-ui';

interface CarouselDotTabsProps {
    maxDots: number;
    activeDot: number;
    onChangeSlide: (index: number) => void;
    dotColor?: string;
    activeDotColor?: string;
}

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '2rem 0',
        gap: '1rem',
    },
    button: {
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        border: 0,
        background: 'none',
        padding: 0,
    },
    dot: (color?: string) => ({
        backgroundColor: color ? color : v2Colors.core.cloud,
        height: 11,
        width: 11,
        borderRadius: 6,
        margin: 3,
    }),
    active: (color?: string) => ({
        backgroundColor: color ? color : v2Colors.core.innovation,
    }),
} as const;

export function CarouselDotTabs({
    maxDots,
    activeDot,
    onChangeSlide,
    dotColor = '',
    activeDotColor = '',
}: CarouselDotTabsProps): JSX.Element | null {
    const dotsArray = Array.from(Array(maxDots).keys());

    return dotsArray.length > 1 ? (
        <Grid css={classes.root}>
            {dotsArray.map((dot) => {
                const isActive = dot === activeDot;

                return (
                    <button
                        key={dot}
                        css={classes.button}
                        onClick={() => onChangeSlide(dot)}
                        aria-label={`Go to slide ${dot + 1}`}
                        tabIndex={isActive ? -1 : 0}
                    >
                        <div css={[classes.dot(dotColor), isActive ? classes.active(activeDotColor) : null]} />
                    </button>
                );
            })}
        </Grid>
    ) : null;
}
