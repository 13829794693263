import React from 'react';

export function TwitterIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 18 18'
            width='20'
            height='20'
            color='#fff'
            {...props}
        >
            <path
                className='twitter-icon'
                fill='currentColor'
                d='M16.15,5.33c0,.16,0,.32,0,.48a10.43,10.43,0,0,1-10.5,10.5A10.41,10.41,0,0,1,0,14.65a6.55,6.55,0,0,0,.89,0,7.35,7.35,0,0,0,4.58-1.58A3.69,3.69,0,0,1,2,10.56a4,4,0,0,0,.7.06,3.91,3.91,0,0,0,1-.12,3.7,3.7,0,0,1-3-3.62v0A3.72,3.72,0,0,0,2.4,7.3,3.68,3.68,0,0,1,1.26,2.36a10.46,10.46,0,0,0,7.6,3.86,4.69,4.69,0,0,1-.09-.84,3.69,3.69,0,0,1,6.39-2.52A7.16,7.16,0,0,0,17.5,2a3.74,3.74,0,0,1-1.62,2A7.55,7.55,0,0,0,18,3.43a7.76,7.76,0,0,1-1.85,1.9Z'
            />
        </svg>
    );
}
