import React from 'react';
import { Grid } from '@mui/material';
import { Picture } from '../../Picture';
import { getLinkTargetValue } from './SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { fourColumnImagesStyle as classes } from '../styleObjects/FourColumnImages';
import { Heading } from '@web-for-marketing/react-ui';
import { LinkImageBox } from '@models/section/fourColumnImageSection';

interface LinkImageBoxesProps {
    content: LinkImageBox[];
    lazyLoad: boolean;
}

export function LinkImageBoxes({ content, lazyLoad }: LinkImageBoxesProps): JSX.Element {
    return (
        <>
            {content.map(({ image, linkText, linkPath, linkTarget, ariaLabel }, index) => (
                <Grid
                    container
                    item
                    direction='column'
                    xs={12}
                    md={3}
                    key={index}
                    css={classes.cardContainer}
                    data-testid='linksId'
                >
                    <CMSLink
                        href={linkPath}
                        target={getLinkTargetValue(linkTarget)}
                        css={classes.linkContainer}
                        aria-label={ariaLabel || ''}
                    >
                        {image.imagePath ? (
                            <Picture
                                imagePath={image.imagePath}
                                mobilePath={image.mobilePath}
                                alt={image.imageAlt}
                                mobileAlt={image.mobileAlt}
                                css={classes.media}
                                className='IeFix-Media'
                                lazyLoad={lazyLoad}
                            />
                        ) : null}
                        <Grid container direction='row' wrap='nowrap' alignItems='center'>
                            <Heading variant='h4' component='h2' css={classes.linkText}>
                                {linkText}
                            </Heading>
                        </Grid>
                    </CMSLink>
                </Grid>
            ))}
        </>
    );
}
