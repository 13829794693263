import React from 'react';

export function EnvironmentIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M13,1v3h-2V1h2ZM4.9,3.5l2.1,2.1-1.4,1.4-2.1-2.1,1.4-1.4ZM20.5,4.9l-2.1,2.1-1.4-1.4,2.1-2.1,1.4,1.4ZM14.8,9.2c-1.6-1.6-4.1-1.6-5.7,0-1.6,1.6-1.6,4.1,0,5.7,1.6,1.6,4.1,1.6,5.7,0s1.6-4.1,0-5.7ZM7.8,7.8c2.3-2.3,6.1-2.3,8.5,0,2.3,2.3,2.3,6.1,0,8.5-2.3,2.3-6.1,2.3-8.5,0-2.3-2.3-2.3-6.1,0-8.5ZM1,11h3v2H1v-2ZM20,11h3v2h-3v-2ZM7.1,18.4l-2.1,2.1-1.4-1.4,2.1-2.1,1.4,1.4ZM18.4,16.9l2.1,2.1-1.4,1.4-2.1-2.1,1.4-1.4ZM13,20v3h-2v-3h2Z'
                    />
                </g>
            </g>
        </svg>
    );
}
