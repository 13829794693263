import React from 'react';
import Grid, { GridDirection, GridProps } from '@mui/material/Grid';
import { threeColumnCardsStyle as classes } from './styleObjects/ThreeColumnCards';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { ShortStackThreeColumnCard } from './ShortStackThreeColumnCard';
import { Card } from '../Cards/Card';
import { cardTypes } from '../../helpers/ObjectTypeModels/Card';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { DefaultHeadingProps, Heading } from '@web-for-marketing/react-ui';
import { ThreeColumnCardsContent } from '@models/section/threeColumnCardsSection';
import { SectionAnimation } from '@models/section';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';

type ThreeColumnCardsProps = ThreeColumnCardsContent & {
    animations?: SectionAnimation;
    customProps?: {
        columnWidth?: number;
        cardDirection?: GridDirection;
        sectionContainer?: {
            verticalFix?: boolean;
        };
        titleContainer?: GridProps;
        bodyContainer?: GridProps;
        title?: DefaultHeadingProps;
    };
    zIndex: number;
    lazyLoad: boolean;
    headerSection?: boolean;
};

export function ThreeColumnCards({
    cards,
    title,
    animations,
    zIndex,
    customProps,
    align,
    textAlign,
    lazyLoad,
    type,
    headerSection,
}: ThreeColumnCardsProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    return (
        <>
            {type === 'shortStack' ? (
                <ShortStackThreeColumnCard
                    cards={cards}
                    title={title}
                    align={align}
                    textAlign={textAlign}
                    zIndex={zIndex}
                    lazyLoad={lazyLoad}
                    animations={animations}
                    headerSection={headerSection}
                />
            ) : (
                <Grid
                    data-testid='LongStackThreeColumnCard'
                    container
                    className={animations ? 'animateContainers' : ''}
                    item
                    md={customProps?.columnWidth || 12}
                >
                    <GridContainer
                        className={`${
                            animations?.sectionAnimation
                                ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                                : ''
                        }`}
                        css={
                            customProps?.sectionContainer && customProps?.sectionContainer.verticalFix
                                ? classes.verticalContainer
                                : ''
                        }
                        style={{ zIndex: zIndex }}
                        direction='column'
                    >
                        <Grid item {...customProps?.titleContainer}>
                            {title ? (
                                <>
                                    <Heading
                                        variant='h2'
                                        component={headerSection ? 'h1' : 'h2'}
                                        css={classes.titles}
                                        align={textAlign}
                                        style={{ marginLeft: textAlign === 'left' ? 0 : 'auto' }}
                                        {...customProps?.title}
                                        data-testid='cardTitle'
                                    >
                                        {title}
                                    </Heading>
                                </>
                            ) : null}
                        </Grid>
                        <ChildContainer
                            justifyContent={align}
                            breakSpaceValue={2}
                            lazyLoad={lazyLoad}
                            direction={customProps?.cardDirection || 'row'}
                            css={[
                                classes.cardParentContainer,
                                customProps?.cardDirection ? classes.mobileDirection : '',
                            ]}
                            data-testid='cardArray'
                        >
                            {Array.isArray(cards)
                                ? cards.map((card, index) => {
                                    const cardContent = {
                                        ...card,
                                        titleVariant: 'h3' as const,
                                        linkTarget: getLinkTargetValue(card.linkTarget),
                                        image: {
                                            ...card.image,
                                            mobilePath: card.image.mobilePath,
                                            lazyLoad: lazyLoad,
                                        },
                                    };

                                    return (
                                        <Grid item key={index} xs={12} md={6} lg={4}>
                                            <Card
                                                card={{
                                                    ...cardTypes.EXTRA_FEATURE_CARD,
                                                    content: cardContent,
                                                }}
                                                href={card.linkPath}
                                                data-testid={`columnCard${index}`}
                                            />
                                        </Grid>
                                    );
                                })
                                : null}
                        </ChildContainer>
                    </GridContainer>
                </Grid>
            )}
        </>
    );
}

ThreeColumnCards.defaultProps = {
    cards: [
        {
            title: '',
            body: '',
            linkPath: '',
            linkText: '',
            linkTarget: true,
            buttonType: 'text',
            image: { imageAlt: '', imagePath: '' },
        },
        {
            title: '',
            body: '',
            linkPath: '',
            linkText: '',
            linkTarget: true,
            buttonType: 'text',
            image: { imageAlt: '', imagePath: '' },
        },
        {
            title: '',
            body: '',
            linkPath: '',
            linkText: '',
            linkTarget: true,
            buttonType: 'text',
            image: { imageAlt: '', imagePath: '' },
        },
    ],
    align: 'flex-start',
    textAlign: 'center',
    animations: {
        animationsEnabled: false,
    },
    type: 'longStack',
};
