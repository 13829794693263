import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Grid, CardMedia, Dialog } from '@mui/material';
import { pageCoveringPopOutStyle as classes } from '../styleObjects/PageCoveringPopOut';
import { ClosePopoutButton } from './ClosePopoutButton';
import { ProfileInfo } from './ProfileInfo';
import { NavigationButton } from './NavigationButton';
import { GridContainer } from '../GeotabGridComponents';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import { EditorSocialLink } from '../EditorSocialLink';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { ClassNames } from '@emotion/react';
import { ProfileImageBox } from '@models/section/fourColumnImageSection';
import { visuallyHidden } from '@mui/utils';

interface ImagePopoutProps {
    imageBoxes: ProfileImageBox[];
    openIndex: number | null;
    onClose: () => void;
    onIncrementIndex: (value: number) => void;
}

interface WindowContent extends Partial<ProfileImageBox> {
    nextName?: string;
    nextTitle?: string;
    prevName?: string;
    prevTitle?: string;
}

let windowContent: WindowContent = {
    name: undefined,
    title: undefined,
    image: undefined,
    popOutWindow: undefined,
    nextName: undefined,
    nextTitle: undefined,
    prevName: undefined,
    prevTitle: undefined,
};

export function ImagePopout({ openIndex, onClose, imageBoxes, onIncrementIndex }: ImagePopoutProps): JSX.Element {
    const open = openIndex !== null;
    const nextPrevContainer = useRef<HTMLDivElement>(null);
    const nextPrevContainerHeight =
        nextPrevContainer && nextPrevContainer.current && nextPrevContainer.current.clientHeight;
    const [bottomContainerHeight, setBottomContainerHeight] = useState(131);
    const [isMobile] = useAtom(isMobileAtom);
    const { prefixStringWithBaseUrl } = useStaticValues();
    const { selectedLanguageTranslation } = useTranslation();

    if (imageBoxes && openIndex !== null) {
        windowContent = { ...imageBoxes[openIndex] };
        windowContent.nextName = openIndex !== imageBoxes.length - 1 ? imageBoxes[openIndex + 1].name : undefined;
        windowContent.nextTitle = openIndex !== imageBoxes.length - 1 ? imageBoxes[openIndex + 1].title : undefined;
        windowContent.prevName = openIndex ? imageBoxes[openIndex - 1].name : undefined;
        windowContent.prevTitle = openIndex ? imageBoxes[openIndex - 1].title : undefined;
    }

    const imagePopoutTranslation = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'imagePopout');
    }, [selectedLanguageTranslation]);

    const containerAlignStyle = (): string => {
        let alignStyle = 'flex-end';

        if (windowContent.prevName && windowContent.nextName) {
            alignStyle = 'space-between';
        } else if (windowContent.prevName) {
            alignStyle = 'flex-start';
        }

        return alignStyle;
    };

    useEffect(() => {
        if (nextPrevContainer.current && nextPrevContainer.current.clientHeight !== bottomContainerHeight) {
            setBottomContainerHeight(nextPrevContainer.current.clientHeight);
        }
    }, [nextPrevContainerHeight, bottomContainerHeight]);

    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    open={open}
                    onClose={onClose}
                    PaperProps={{
                        className: css([
                            classes.popOutContainer,
                            classes.mobileTransparentBackground,
                            open ? classes.openPopOut : '',
                        ]),
                    }}
                    aria-labelledby='image-popout-title'
                    data-testid='imagePopoutId'
                >
                    <h2 style={visuallyHidden} id='image-popout-title'>
                        {windowContent.name}
                    </h2>
                    {!isMobile ? <ClosePopoutButton closeWindow={onClose} /> : null}
                    <Grid
                        container
                        css={[classes.innerContainerOverflow, classes.transparentBackground]}
                        style={{ height: `calc(100% - ${bottomContainerHeight}px)` }}
                    >
                        {windowContent.popOutWindow ? (
                            <>
                                <GridContainer>
                                    {isMobile ? (
                                        <Grid item css={classes.buttonContainer}>
                                            <ClosePopoutButton closeWindow={onClose} />
                                        </Grid>
                                    ) : null}
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={4}
                                        direction='column'
                                        justifyContent='flex-start'
                                        alignItems='flex-start'
                                    >
                                        {windowContent.image ? (
                                            <CardMedia
                                                component='img'
                                                css={classes.profileMedia}
                                                src={prefixStringWithBaseUrl(windowContent.image.imagePath)}
                                                alt={windowContent.image.imageAlt || ''}
                                                aria-hidden={`${!!windowContent.image.imageAlt}` || 'true'}
                                            />
                                        ) : null}
                                        <Grid container css={classes.socialContainer} wrap='nowrap'>
                                            {windowContent.popOutWindow.twitterLink ? (
                                                <EditorSocialLink
                                                    name={windowContent.name || ''}
                                                    socialType='twitter'
                                                    link={windowContent.popOutWindow.twitterLink}
                                                    css={classes.shareSocialIcon}
                                                />
                                            ) : null}
                                            {windowContent.popOutWindow.linkedInLink ? (
                                                <EditorSocialLink
                                                    name={windowContent.name || ''}
                                                    socialType='linkedIn'
                                                    link={windowContent.popOutWindow.linkedInLink}
                                                    css={classes.shareSocialIcon}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <ProfileInfo
                                        name={windowContent.name}
                                        title={windowContent.title}
                                        description={windowContent.popOutWindow.description || ''}
                                    />
                                </GridContainer>
                            </>
                        ) : null}
                    </Grid>
                    <Grid
                        container
                        css={[classes.nextPrevOuterContainer, classes.transparentBackground]}
                        ref={nextPrevContainer}
                    >
                        {windowContent.popOutWindow ? (
                            <>
                                <GridContainer
                                    css={classes.nextPrevContainer}
                                    style={{ justifyContent: containerAlignStyle() }}
                                >
                                    <span>
                                        {windowContent.prevName ? (
                                            <NavigationButton
                                                css={classes.leftAlignChildren}
                                                name={windowContent.prevName}
                                                title={windowContent.prevTitle || ''}
                                                directionText={imagePopoutTranslation.readPrevious}
                                                onClick={() => {
                                                    onIncrementIndex(-1);
                                                }}
                                            />
                                        ) : null}
                                    </span>
                                    <span>
                                        {windowContent.nextName ? (
                                            <NavigationButton
                                                css={classes.rightAlignChildren}
                                                name={windowContent.nextName}
                                                title={windowContent.nextTitle || ''}
                                                directionText={imagePopoutTranslation.readNext}
                                                onClick={() => {
                                                    onIncrementIndex(1);
                                                }}
                                            />
                                        ) : null}
                                    </span>
                                </GridContainer>
                            </>
                        ) : null}
                    </Grid>
                </Dialog>
            )}
        </ClassNames>
    );
}
