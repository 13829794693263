import { httpGet } from './apiService';
import { Industry, MarketplaceSolution, PartnerSolutionCategory, Region } from '@models/marketplaceFilters';
import { MarketplacePagedResult } from '@models/section/marketplaceSolutionsSection';

export async function getMarketplaceRegionFilter(): Promise<MarketplacePagedResult<Region>> {
    const value = await httpGet('/api/mp_proxy/regions?PageSize=1000');
    return value.json();
}

export async function getMarketplaceCategoryFilter(): Promise<PartnerSolutionCategory[]> {
    const value = await httpGet('/api/mp_proxy/categories/with-sub-filters');
    return value.json();
}

export async function getMarketplaceIndustryFilter(): Promise<Industry[]> {
    const value = await httpGet('/api/mp_proxy/industries/with-default-translations');
    return value.json();
}

export const getAllMarketplaceSolutions = async (api: string): Promise<MarketplacePagedResult<MarketplaceSolution>> => {
    return await httpGet(api).then((result) => result.json());
};
