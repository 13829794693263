import React from 'react';

export function AssetsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M2.3,3.6c-.6,0-1,.5-1,1v11.5c0,.6.5,1,1,1h1.2c.5,1.8,2.1,3.1,4.1,3.1s3.6-1.3,4.1-3.1h1.2c.4,0,.7-.2.9-.5l1.8-2.7h2.1v4.2h-.5v2.1h3.1v-2.1h-.5v-4.2h2.1c.6,0,1-.5,1-1V4.7c0-.6-.5-1-1-1H2.3ZM20.6,12v-6.3H3.4v9.4h.1c.5-1.8,2.1-3.1,4.1-3.1s3.6,1.3,4.1,3.1h.6l1.8-2.7c.2-.3.5-.5.9-.5h5.8ZM7.5,18.3c1.2,0,2.1-.9,2.1-2.1s-.9-2.1-2.1-2.1-2.1.9-2.1,2.1.9,2.1,2.1,2.1Z'
                    />
                </g>
            </g>
        </svg>
    );
}
