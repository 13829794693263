import React from 'react';

export function ConfigurationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='45'
            height='44'
            viewBox='0 0 45 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g id='settings 2, gear, preferences'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.6012 3.90219C22.1593 3.58827 22.8407 3.58827 23.3988 3.90219L38.0655 12.1521C38.6428 12.4768 39 13.0876 39 13.75V30.25C39 30.9123 38.6428 31.5232 38.0655 31.8479L23.3988 40.0977C22.8407 40.4116 22.1593 40.4116 21.6012 40.0977L6.93458 31.8484C6.35727 31.5237 6 30.9129 6 30.2505L6 13.7499C6 13.0876 6.35725 12.4768 6.93453 12.152L21.6012 3.90219ZM9.66667 14.8222V29.1783L22.5 36.3964L35.3334 29.1777V14.8222L22.5 7.60354L9.66667 14.8222ZM22.5 18.3334C20.475 18.3334 18.8334 19.975 18.8334 22.0001C18.8334 24.0251 20.475 25.6667 22.5 25.6667C24.5251 25.6667 26.1667 24.0251 26.1667 22.0001C26.1667 19.975 24.5251 18.3334 22.5 18.3334ZM15.1667 22.0001C15.1667 17.95 18.45 14.6667 22.5 14.6667C26.5501 14.6667 29.8334 17.95 29.8334 22.0001C29.8334 26.0502 26.5501 29.3334 22.5 29.3334C18.45 29.3334 15.1667 26.0502 15.1667 22.0001Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
