import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const classes = {
    arrowButton: (solutionLength: number) => ({
        borderRadius: '50%',
        padding: '1rem !important',
        '& svg': {
            color: 'currentColor',
            width: '2.7rem',
            height: '2.7rem',
        },
        margin: '1.8rem',
        display: solutionLength <= 4 ? 'none' : 'inline-flex',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            display: solutionLength <= 3 ? 'none' : 'inline-flex',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    }),
    allSolutionButtonContainer: {
        justifyContent: 'flex-end',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            justifyContent: 'flex-start',
        },
    },
    allSolutionButton: {
        padding: '1.8rem 2.4rem !important',
    },
    marketplaceIcon: {
        marginRight: '2rem',
    },
    newTabIcon: {
        marginLeft: '1rem',
        height: '1.8rem',
        width: '1.8rem',
    },
    newTabButton: {
        padding: '0.55rem 0.6rem',
        borderRadius: '50%',
        background: v2Colors.background.light,
        lineHeight: 1,
    },
    bottomItemMargin: {
        marginBottom: '4.7rem',
    },
    carouselParentContainer: {
        [`@media (max-width: ${breakpoints.xl}px)`]: {
            margin: '0 auto',
            maxWidth: 1400,
        },
    },
    carouselFixedContainer: {
        position: 'relative',
        overflow: 'hidden',
        padding: '3.5rem 6.9rem',
        borderRadius: '8px',
        backgroundColor: v2Colors.background.light,
        boxSizing: 'border-box',
        maxWidth: 1400,

        width: '100%',
        [`@media (min-width: ${breakpoints.md}px)`]: {
            '&::after': {
                content: '""',
                width: '4rem',
                height: '100%',
                position: 'absolute',
                right: 0,
                top: 0,
            },
        },
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            margin: 0,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginRight: '-3.6rem',
            marginLeft: '-3.6rem',
            borderRadius: 'unset',
            padding: '3.5rem 0',
            width: 'calc(100% + 7.2rem)',
        },
    },
    scrollingWrapper: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'calc(25% + 1px)',
        transform: 'translateX(25%)',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            gridAutoColumns: 'calc(33% + 1px)',
            transform: 'translateX(33%)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '0 2rem',
            scrollPaddingLeft: '2rem',
            scrollPaddingRight: '2rem',
            scrollSnapType: 'x mandatory',
            gridAutoColumns: '45%',
            transform: 'translateX(45%)',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            gridAutoColumns: '80%',
            transform: 'translateX(80%)',
        },
    },
    idle: {
        transform: 'none !important',
        transition: 'transform 0.5s cubic-bezier(0.23, 1, 0.32, 1)',
    },
    reverse: {
        transform: 'translateX(-25%)',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            transform: 'translateX(-33%)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            transform: 'translateX(-45%)',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            transform: 'translateX(-80%)',
        },
    },
    card: {
        backgroundColor: v2Colors.core.snow,
        maxHeight: '50rem',
        border: '1px solid #C7CBD2',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 2px rgba(102, 120, 140, 0.05)',
        transition: 'transform ease 500ms, box-shadow ease 500ms',
        margin: '1rem .8rem',
        scrollSnapAlign: 'end',
        '&:hover': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
            transform: 'translateY(-1rem)',
        },
        '&:focus': {
            boxShadow: '0 0 .6rem rgba(102,120,140,0.1), 0 1rem 1rem rgba(102,120,140,0.1)',
            borderColor: v2Colors.core.innovation,
            outline: 'none',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            '&:last-of-type': {
                marginRight: '2rem',
            },
        },
    },
    cardPlaceholder: {
        height: '25rem',
    },
    cardPlaceholderImage: {
        height: '8rem',
        width: '8rem',
        margin: '2.4rem',
        borderRadius: '6px',
        backgroundColor: v2Colors.background.light,
    },
    cardTextContainer: {
        padding: '2.4rem',
        '& > *:not(:last-child)': {
            marginBottom: '1rem',
        },
        '& picture': {
            width: 'auto',
        },
    },
    cardTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '4',
    },
    title: {
        marginBottom: '1rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    body: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    picture: {
        height: '8rem',
        width: '8rem',
        marginBottom: '1.6rem',
    },
    description: {
        maxWidth: '500px',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            WebkitLineClamp: 3,
        },
    },
    titleContainer: {
        maxWidth: 1400,
        margin: '0 auto',
    },
} as const;
