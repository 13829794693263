import React from 'react';

export function PingIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M12,3.7c-3.4,0-6.2,2.8-6.2,6.2s1.6,5.3,3.4,7.3c.9,1,1.7,1.8,2.4,2.4.2.1.3.3.5.4.1-.1.3-.2.5-.4.7-.6,1.5-1.4,2.4-2.4,1.8-2,3.4-4.7,3.4-7.3s-2.8-6.2-6.2-6.2ZM12,21.3q-.6.8-.6.8h0s0,0,0,0h0s0,0,0,0c0,0-.1-.1-.2-.2-.2-.2-.5-.4-.9-.7-.7-.6-1.7-1.5-2.6-2.6-1.9-2.1-3.9-5.2-3.9-8.7S7.4,1.6,12,1.6s8.3,3.7,8.3,8.3-2,6.5-3.9,8.7c-.9,1.1-1.9,2-2.6,2.6-.4.3-.7.6-.9.7-.1,0-.2.1-.2.2,0,0,0,0,0,0h0s0,0,0,0h0s0,0-.6-.8ZM12,21.3l.6.8c-.4.3-.9.3-1.2,0l.6-.8ZM12,8.4c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.7-1.6-1.6-1.6ZM8.4,9.9c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6-1.6,3.6-3.6,3.6-3.6-1.6-3.6-3.6Z'
                    />
                </g>
            </g>
        </svg>
    );
}
