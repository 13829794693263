import React from 'react';
import { Grid } from '@mui/material';
import { ContentRowsStyle as classes } from '../styleObjects/ContentRowsStyle';
import { getLinkTargetValue } from './SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { RowList } from '@models/section/linkListSection';

interface LinkListItemProps {
    listItem: RowList;
}

export function LinkListItem({ listItem }: LinkListItemProps): JSX.Element {
    const { linkPath, linkText, linkTarget, ariaLabel } = listItem;

    return (
        <Grid item xs={12} md={6}>
            <CMSLink
                type='button'
                href={linkPath}
                aria-label={ariaLabel}
                target={getLinkTargetValue(linkTarget)}
                variant='text'
                css={[classes.linkTextButton, classes.supportContent]}
            >
                {linkText}
            </CMSLink>
        </Grid>
    );
}
