import React from 'react';

export function BatterIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M1,5h19v3h3v8h-3v3H1V5ZM18,7H3v10h15V7ZM20,14h1v-4h-1v4ZM6,9c.6,0,1,.4,1,1v4c0,.6-.4,1-1,1s-1-.4-1-1v-4c0-.6.4-1,1-1ZM10.5,9c.6,0,1,.4,1,1v4c0,.6-.4,1-1,1s-1-.4-1-1v-4c0-.6.4-1,1-1ZM15,9c.6,0,1,.4,1,1v4c0,.6-.4,1-1,1s-1-.4-1-1v-4c0-.6.4-1,1-1Z'
                    />
                </g>
            </g>
        </svg>
    );
}
