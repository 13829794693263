import { colors, breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const multistepSectionStyles = {
    mainContentContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '3.5rem',
        },
    },
    mainTitle: {
        marginBottom: '1.5rem',
        width: '100%',
    },
    cardsImage: {
        marginBottom: '1.5rem',
    },
    cardTitle: {
        marginBottom: '1.5rem',
        fontWeight: '700',
    },
    cardDescription: {
        marginBottom: '1.5rem',
    },
    multistepCardContainer: {
        borderRadius: '1rem',
        border: `1px solid ${v2Colors.border.primary}`,
        padding: '5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '2.5rem',
            width: '100%',
        },
    },
    linkText: {
        fontSize: '2rem',
        fontWeight: '700',
        color: colors.core.innovation.light,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.6rem',
        },
    },
    stepBarContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '3.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            width: '100%',
            order: 3,
        },
    },
    stepBarWrapper: {
        borderLeft: `2px solid ${v2Colors.border.primary}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            borderLeft: 'none',
            flexDirection: 'row',
            margin: '3.5rem 0',
            width: '95%',
        },
    },
    stepDot: {
        listStyle: 'none',
        position: 'relative',
        width: '7rem',
        height: '7rem',
        textAlign: 'center',
        marginLeft: '-3.8rem',
        border: `1px solid ${colors.interactive.primary}`,
        borderRadius: '5rem',
        background: '#FFFFFF',
        cursor: 'pointer',
        '& p': {
            marginBottom: 0,
            paddingTop: '0.7rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginLeft: 0,
            width: '5rem',
            height: '5rem',
            padding: '0 !important',
            '& p': {
                fontSize: '2.2rem',
                marginBottom: 0,
                paddingTop: '0.5rem',
            },
        },
    },
    currentStepDot: {
        background: colors.interactive.primary,
        '&:hover, &:focus': {
            background: colors.interactive.primary,
        },
        '& p': {
            color: '#FFFFFF',
        },
    },
    regularStepDot: {
        background: '#FFFFFF',
        '&:hover, &:focus': {
            background: '#FFFFFF',
        },
    },
    dotNumber: {
        color: colors.core.innovation.light,
    },
} as const;
