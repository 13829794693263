import React from 'react';

export function ConnectivityIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M6.3,3.7c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.7-1.6-1.6-1.6ZM2.6,5.2c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6-1.1,3-2.6,3.5v2.2h9.4v-2.2c-1.5-.4-2.6-1.8-2.6-3.5s1.6-3.6,3.6-3.6,3.6,1.6,3.6,3.6-1.1,3-2.6,3.5v3.3c0,.6-.5,1-1,1h-4.7v2.2c1.5.4,2.6,1.8,2.6,3.5s-1.6,3.6-3.6,3.6-3.6-1.6-3.6-3.6,1.1-3,2.6-3.5v-2.2h-4.7c-.6,0-1-.5-1-1v-3.3c-1.5-.4-2.6-1.8-2.6-3.5ZM17.7,3.7c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.7-1.6-1.6-1.6ZM12,17.2c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.7-1.6-1.6-1.6Z'
                    />
                </g>
            </g>
        </svg>
    );
}
