import React from 'react';
import { Grid } from '@mui/material';
import { ContentRowsStyle as classes } from '../styleObjects/ContentRowsStyle';
import { LinkListItem } from './LinkListItem';
import { Picture } from '../../Picture';
import { Heading, LazyLoadContainer } from '@web-for-marketing/react-ui';
import { MobileFriendlySectionImage } from '@models/section';
import { RowList } from '@models/section/linkListSection';

export interface ContentRowRow {
    image: MobileFriendlySectionImage;
    subtitle?: string;
    list: RowList[];
}

interface ContentRowProps {
    lazyLoad?: boolean;
    rowContent: ContentRowRow;
    shouldIncreaseHeadingLevel?: boolean;
}

export function ContentRow({ rowContent, lazyLoad, shouldIncreaseHeadingLevel }: ContentRowProps): JSX.Element | null {
    const { imageAlt, imagePath, mobilePath, mobileAlt } = rowContent.image;

    if (rowContent) {
        return (
            <Grid container css={classes.rowContainers}>
                <LazyLoadContainer component={Grid} container item xs={12} md={4}>
                    {imagePath || mobilePath ? (
                        <Picture
                            imagePath={imagePath}
                            mobilePath={mobilePath}
                            alt={imageAlt}
                            mobileAlt={mobileAlt}
                            css={classes.rowIcon}
                            lazyLoad={lazyLoad}
                            aria-hidden='true'
                            fullWidth={false}
                        />
                    ) : null}

                    {rowContent.subtitle ? (
                        <Heading
                            variant='h3'
                            weight='regular'
                            component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                            css={classes.rowTitle}
                        >
                            {rowContent.subtitle}
                        </Heading>
                    ) : null}
                </LazyLoadContainer>
                <Grid container item xs={12} md={8} direction='row' alignItems='flex-start'>
                    {rowContent.list.length
                        ? rowContent.list.map((listItem, index) => <LinkListItem key={index} listItem={listItem} />)
                        : null}
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
}
