import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

export function useDeepCompareMemo<T>(callback: () => T, dependencies: unknown[]): T {
    const dependenciesRef = useRef<unknown[]>([]);
    const memoizedValueRef = useRef<T>();

    if (!isEqual(dependenciesRef.current, dependencies)) {
        dependenciesRef.current = dependencies;
        memoizedValueRef.current = callback();
    }

    return memoizedValueRef.current as T;
}
