import React from 'react';
import { Button } from '@mui/material';
import { accordionListStyles as classes } from './styleObjects/accordionList';
import { GridContainer } from '../GeotabGridComponents';

interface ListCategoriesProps {
    categories: string[];
    selectedCategory: string;
    onCategorySelected: (category: string) => void;
    zIndex: number;
}

export function ListCategories({
    categories = [],
    selectedCategory,
    onCategorySelected,
    zIndex,
}: ListCategoriesProps): JSX.Element {
    return (
        <GridContainer justifyContent='space-between' css={classes.categoryContainer} style={{ zIndex: zIndex }}>
            {categories.map((category, index) => (
                <Button
                    color='primary'
                    css={classes.categoryButton}
                    key={`${category}-${index}`}
                    name={category}
                    aria-label={`Show ${category} below`}
                    variant='text'
                    onClick={() => {
                        onCategorySelected(category);
                    }}
                    disabled={selectedCategory === category}
                    aria-pressed={selectedCategory === category}
                >
                    {category}
                </Button>
            ))}
        </GridContainer>
    );
}
