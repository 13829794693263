import React from 'react';

export function DownChevronIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='52'
            height='17'
            viewBox='0 0 52 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50.3642 0.166748L26 15.1397L1.69217 0.166748L0 0.81154L26 16.8334L52 0.801123L50.3642 0.166748Z'
                fill='white'
            />
        </svg>
    );
}
