import { v2Colors } from '@web-for-marketing/react-ui';

export const collapseStyles = {
    collapseSections: {
        cursor: 'pointer',
        padding: '1rem 0',
        position: 'relative',
    },
    collapseBorder: {
        padding: 0,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        '&:last-child': {
            borderBottom: 'unset !important',
        },
    },
    collapseSectionsWide: {
        cursor: 'pointer',
        padding: '2rem 6rem 2rem 2rem',
        position: 'relative',
        '&:hover': {
            backgroundColor: 'rgba(1, 23, 41, 0.01) !important',
        },
        '&:focus': {
            backgroundColor: 'rgba(1, 23, 41, 0.02) !important',
        },
        '&:active': {
            backgroundColor: 'rgba(1, 23, 41, 0.02) !important',
        },
    },
    collapseContainer: {
        position: 'static',
        borderTop: 'none',
    },
    collapseContentContainer: {
        position: 'static',
        borderTop: 'none',
        boxSizing: 'border-box',
        padding: '0 2rem 3rem',
        backgroundColor: 'transparent !important',
    },
    collapseContentWide: {
        position: 'static',
        borderTop: 'none',
        padding: '0 2rem 0',
        backgroundColor: 'transparent !important',
        transitionTimingFunction: 'cubic-bezier(0.3, 0.76, 0.36, 0.95)',
        transition: 'transform 200ms, zIndex 200ms, visibility 200ms, opacity 200ms, height 200ms',
        '& p': {
            paddingBottom: '3rem',
        },
    },
    collapseExpanded: {
        transform: 'translateY(0) !important',
        boxSizing: 'border-box',
        visibility: 'visible',
        opacity: 1,
        zIndex: 0,
    },
    collapseContracted: {
        padding: 0,
        transform: 'translateY(-50%) !important',
        visibility: 'hidden',
        opacity: 0,
        zIndex: -1,
    },
    collapseParentContainer: {
        position: 'static',
        padding: 0,
        borderTop: 'none',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        '&:last-child': {
            borderBottom: 'unset !important',
        },
    },
} as const;

export const generalFormStyle = {
    categoryHeading: {
        color: v2Colors.text.secondary,
        fontWeight: 700,
    },
    switchLeft: {
        transform: 'translateX(-10px)',
    },
    tooltip: {
        fontSize: '1.4rem',
        border: `1px solid ${v2Colors.core.smoke}`,
        color: v2Colors.core.blackTarr,
        backgroundColor: v2Colors.core.snow,
    },
    icon: {
        height: '1.6rem',
        width: '1.6rem',
        verticalAlign: 'text-top',
    },
    inlineContainer: {
        display: 'inline-block',
    },
    deleteItemButton: {
        '& svg': {
            padding: '0.4rem',
            boxSizing: 'border-box',
            strokeWidth: '0.2rem',
        },
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        top: '50%',
        right: '0',
        position: 'absolute',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '0.6rem',
    },
    moveItemUpButton: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        top: '50%',
        right: '6rem',
        position: 'absolute',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '0.6rem',
    },
    moveItemDownButton: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        top: '50%',
        right: '3rem',
        position: 'absolute',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '0.6rem',
    },
    expandButtonContainer: {
        position: 'absolute',
        width: 'auto !important',
        top: '50%',
        right: '2rem',
        transform: 'translateY(-50%)',
    },
    dropdownMenu: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        '& ul': {
            fontSize: '1.6rem',
            color: v2Colors.core.slate,
            lineHeight: 1.5,
        },
        '& ul option': {
            padding: '0.5rem 3rem 0.5rem 1rem',
            cursor: 'pointer',
            borderRadius: '4px',
        },
        '& ul option:hover': {
            backgroundColor: v2Colors.core.cloud,
            color: v2Colors.core.deepSpace,
        },
        '& ul option:focus': {
            outlineColor: v2Colors.core.innovation,
            outlineWidth: '.5px',
            borderRadius: '4px',
        },
    },
    nativeInput: {
        fontSize: '1.6rem',
        lineHeight: 1,
        color: v2Colors.core.deepSpace,
        '&:focus': {
            color: v2Colors.core.slate,
            backgroundColor: 'rgba(255,255,255,0)',
            border: 'none',
        },
    },
    labelSize: {
        fontSize: '1.2rem',
    },
    noBorder: {
        border: 'unset !important',
    },
    // Default form margins:
    formItemMargin: {
        marginBottom: '2.5rem',
    },
    formItemTopMargin: {
        marginTop: '2.5rem',
    },
    subHeadingMargin: {
        marginBottom: '1rem',
    },
    tooltipLabelMargin: {
        marginTop: '1rem',
    },
    formCollapseTopMargin: {
        marginTop: '1.5rem',
    },
    imageThumbnail: {
        height: '5.8em',
        width: 'auto',
        marginLeft: '1.5rem',
    },
    wideImageThumbnail: {
        height: '5.8em',
        width: '100%',
        marginTop: '1rem',
    },
    sliderContainerPadding: {
        padding: '1.5rem',
        width: '100%',
    },
    error: {
        color: v2Colors.core.ember,
        '& fieldset': {
            borderColor: `${v2Colors.core.ember} !important`,
        },
    },
    listItems: {
        position: 'relative',
        marginBottom: '2rem !important',
        overflowY: 'hidden !important' as 'hidden',
    },
    deleteListItemButton: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        top: '30%',
        right: '-2rem',
        position: 'absolute',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '0.6rem',
    },
    listContainer: {
        display: 'block',
        right: '2rem',
        padding: '0.5em 1em',
        top: '0',
        minHeight: 'unset',
        position: 'absolute',
        transition: 'top ease 0.1s, right ease 0.1s, transform ease 0.1s',
    },
    listOutlineContainer: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        position: 'relative',
        borderRadius: 10,
    },
    inputHeightClear: {
        '& select': {
            height: 'auto !important',
        },
    },
    marginRight: {
        marginRight: '1rem',
    },
    bannerSettings: {
        fontSize: '2.2rem',
    },
    fullWidth: {
        width: '100%',
    },
    copyAltButton: {
        marginTop: '2.5rem',
        padding: '1rem !important',
    },
    ...collapseStyles,
} as const;
