import React from 'react';

export function InstallationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M11.9,2.3c1.2-1.2,3.2-1.1,4.3,0,1.2,1.2,1.1,3.1,0,4.2l-2.1,2.1s0,0,0,0c0,0,0,.1,0,.3,0,.3.2.5.3.6.1.1.3.2.6.3.1,0,.2,0,.3,0,0,0,0,0,0,0l2.1-2.1c1.1-1.1,3-1.3,4.2,0,1.1,1.1,1.3,3.1,0,4.3l-2.9,2.9c-.5.5-1.2.8-1.8.9-.6.1-1.2.1-1.7.1h-.8c-.7,0-1.4.3-1.9.8l-4.7,4.7c-1.4,1.4-3.8,1.4-5.2,0-1.4-1.4-1.4-3.8,0-5.2l4.7-4.7c.5-.5.8-1.2.8-1.9v-.8c0-.5,0-1.1.1-1.7.1-.6.4-1.3.9-1.8l2.9-2.9ZM14.8,3.8c-.4-.4-1.2-.4-1.6,0l-2.9,2.9c-.1.1-.3.4-.4.8,0,.4,0,.9,0,1.4v.8c0,1.2-.5,2.4-1.3,3.2l-4.7,4.7c-.7.7-.7,1.8,0,2.5.7.7,1.8.7,2.5,0l4.7-4.7c.9-.9,2-1.3,3.2-1.3h.8c.5,0,.9,0,1.4,0,.4,0,.7-.2.8-.4l2.9-2.9c.4-.4.4-1.1,0-1.6-.4-.4-1-.4-1.5,0l-2.1,2.1c-.6.6-1.4.6-2,.5-.6-.1-1.2-.4-1.6-.8-.4-.4-.7-1-.8-1.6-.1-.6,0-1.4.5-2l2.1-2.1c.4-.4.4-1.1,0-1.5Z'
                    />
                </g>
            </g>
        </svg>
    );
}
