import React from 'react';

export function RightChevronIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='18'
            height='33'
            viewBox='0 0 18 33'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M0.417575 1.41122C-0.139155 1.96795 -0.13925 2.87077 0.41767 3.4276L13.6675 16.6771L0.417571 29.9273C-0.139158 30.4841 -0.139254 31.3869 0.417666 31.9437C0.974491 32.5005 1.87722 32.5005 2.43404 31.9437L16.6921 17.6853C16.9595 17.4179 17.1097 17.0553 17.1097 16.6771C17.1097 16.299 16.9594 15.9363 16.692 15.669L2.43395 1.41132C1.87722 0.854397 0.9744 0.854397 0.417575 1.41122Z'
                fill='#0078D3'
            />
        </svg>
    );
}
