import React from 'react';
import { Grid } from '@mui/material';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { getAnimationStyle } from '../SectionComponents/helpers/AnimationStyleHelper';
import { ResourceBoxesStyle as classes } from '../SectionComponents/styleObjects/ResourceBoxes';
import { cardTypes } from '../../helpers/ObjectTypeModels/Card';
import { Card } from '../Cards/Card';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { SectionAnimation } from '@models/section';
import { ResourceBoxesContent } from '@models/section/resourceBoxesSection';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';

type ResourceBoxesProps = ResourceBoxesContent & {
    animations: SectionAnimation;
    lazyLoad: boolean;
    zIndex: number;
    headerSection: boolean;
};

export function ResourceBoxes({
    cards,
    title,
    animations,
    zIndex,
    lazyLoad,
    params,
    headerSection,
}: ResourceBoxesProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const shouldIncreaseHeadingLevel = headerSection || !title;

    return (
        <Grid
            item
            container
            className={animations ? ' animateContainers' : ''}
            css={params ? classes.container : classes.containerNoColor}
            md={12}
        >
            <GridContainer
                className={`${
                    animations.sectionAnimation
                        ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                        : ''
                }`}
                css={{ zIndex: zIndex }}
                direction='column'
            >
                {title ? (
                    <Grid item css={{ width: '100%' }}>
                        <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} css={classes.titles}>
                            {title}
                        </Heading>
                    </Grid>
                ) : null}
                <ChildContainer direction='row' breakSpaceValue={2} lazyLoad={lazyLoad}>
                    {cards.map((card, index) => {
                        const tileContent = {
                            title: card.title,
                            subtitle: card.subTitle,
                            body: card.body,
                            linkTarget: getLinkTargetValue(card.linkTarget),
                            linkPath: card.linkPath,
                            linkText: card.linkText,
                            ariaLabel: card.ariaLabel,
                            image: {
                                lazyLoad: lazyLoad,
                                ...card.image,
                                mobilePath: card.image.mobilePath,
                            },
                        };

                        return (
                            <div key={index} css={{ width: '100%' }}>
                                <Card
                                    card={{
                                        ...cardTypes.GEOTAB_LONG,
                                        content: tileContent,
                                    }}
                                    reverse={card.inverted}
                                    shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                />
                            </div>
                        );
                    })}
                </ChildContainer>
            </GridContainer>
        </Grid>
    );
}

ResourceBoxes.defaultProps = {
    cards: [
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' }, inverted: false },
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' }, inverted: false },
        { title: '', linkPath: '', linkTarget: true, image: { imageAlt: '', imagePath: '' }, inverted: false },
    ],
    animations: {
        animationsEnabled: false,
    },
    lazyLoad: false,
};
