import { v2Colors, breakpoints } from '@web-for-marketing/react-ui';

export const accordionListStyles = {
    categoryHeadingContainer: {
        paddingTop: '3.2rem',
        paddingBottom: 0,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: 0,
        },
    },
    gridContainerNoTopPadding: {
        paddingTop: 0,
        paddingBottom: '3.2rem',
    },
    noBottomPadding: {
        paddingBottom: 0,
    },
    headingTitle: {
        color: v2Colors.core.geotab,
        marginTop: '6rem',
    },
    subHeading: {
        color: v2Colors.core.slate,
        marginBottom: '3rem',
    },
    contactSubHeading: {
        color: v2Colors.core.slate,
        fontSize: '2.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.6rem',
        },
    },
    contactButtonContainer: {
        textAlign: 'center',
        marginTop: '4rem',
        marginBottom: '6rem',
    },
    categoryContainer: {
        paddinTop: '3.2rem',
        paddingBottom: '3.2rem',
    },
    categoryButton: {
        paddingLeft: 0,
        paddingRight: 0,
        color: `${v2Colors.core.slate} !important`,
        '&:disabled': {
            color: `${v2Colors.core.innovation} !important`,
        },
        '&:first-child': {
            marginLeft: '2%',
        },
        '&:last-child': {
            marginRight: '2%',
        },
    },
    collapsibleListCategoryContainer: {
        paddingTop: '1rem !important',
        paddingBottom: '1rem !important',
        borderBottom: 'none',
        borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
        '&:last-child': {
            borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        },
    },
    collapsibleCategoryName: {
        cursor: 'pointer',
    },
    mobileCategoryListContainer: {
        paddingTop: 0,
    },
    mobileCategoryNameOpen: {
        color: v2Colors.core.innovation,
    },
    mobileLoadMoreButton: {
        marginBottom: '2rem',
    },
    expandCollapseIconButton: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        cursor: 'pointer',
        padding: '0.6rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '0.6rem',
            '& > span > svg': {
                height: '1.5rem',
                width: '1.5rem',
            },
        },
    },
};
