import React from 'react';

export function InstagramIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            width='20'
            height='20'
            color='#fff'
            {...props}
        >
            <path
                className='instagram-icon'
                fill='currentColor'
                d='M15,8.56A6.41,6.41,0,1,0,21.4,15,6.41,6.41,0,0,0,15,8.56Zm0,10.57A4.16,4.16,0,1,1,19.15,15,4.16,4.16,0,0,1,15,19.13Zm8.16-10.8a1.5,1.5,0,1,1-1.5-1.5A1.5,1.5,0,0,1,23.15,8.33ZM27.4,9.88a8.94,8.94,0,0,0-.58-3.07,6.37,6.37,0,0,0-3.65-3.65,9,9,0,0,0-3-.58C18.81,2.52,18.39,2.5,15,2.5s-3.82,0-5.14.08a9,9,0,0,0-3,.58A6.37,6.37,0,0,0,3.18,6.81a9.2,9.2,0,0,0-.58,3c-.06,1.33-.08,1.75-.08,5.14s0,3.82.08,5.14a9.2,9.2,0,0,0,.58,3A6.37,6.37,0,0,0,6.83,26.8a9,9,0,0,0,3,.62c1.33.06,1.75.08,5.14.08s3.82,0,5.14-.08a9,9,0,0,0,3-.58,6.37,6.37,0,0,0,3.65-3.65,9.2,9.2,0,0,0,.58-3c.06-1.33.08-1.75.08-5.14S27.48,11.2,27.4,9.88ZM25.15,20h0a6.92,6.92,0,0,1-.43,2.34,4.13,4.13,0,0,1-2.37,2.37,6.75,6.75,0,0,1-2.31.43c-1.32.06-1.69.08-5.07.08s-3.72,0-5.07-.08a6.75,6.75,0,0,1-2.31-.43,4.15,4.15,0,0,1-2.38-2.37A6.81,6.81,0,0,1,4.76,20c-.06-1.31-.08-1.68-.08-5.06s0-3.72.08-5.07a6.92,6.92,0,0,1,.43-2.34A4.15,4.15,0,0,1,7.57,5.2a7,7,0,0,1,2.31-.43C11.2,4.71,11.57,4.7,15,4.7s3.72,0,5.07.07a7,7,0,0,1,2.31.43A4.13,4.13,0,0,1,24.7,7.57a6.85,6.85,0,0,1,.43,2.32c.06,1.31.08,1.69.08,5.06S25.21,18.69,25.15,20Z'
            />
        </svg>
    );
}
