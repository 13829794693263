import React, { useState } from 'react';
import { VerticalAccordionSectionData } from '@models/section/verticalAccordionSection';
import { Grid } from '@mui/material';
import { Picture } from '@components/Picture';
import { RichTextContent } from '@components/RichTextContent';
import {
    Accordion,
    AccordionPanel,
    AccordionTrigger,
    ForwardIcon,
    Heading,
    LazyLoadContainer,
    Text,
    V2Button,
} from '@web-for-marketing/react-ui';
import { SectionContainer } from './SectionContainer';

export interface VerticalAccordionProps {
    sectionData: VerticalAccordionSectionData;
    headerSection: boolean;
    lazyLoad?: boolean;
}

const classes = {
    fullWidth: {
        width: '100%',
    },
    imageContainer: {
        position: 'relative',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
    },
    image: (hide: boolean) => {
        const imageStyle = {
            borderRadius: '1rem',
            transition: 'opacity 0.5s ease-in-out',
        };

        if (hide) {
            return {
                ...imageStyle,
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
            } as const;
        } else {
            return {
                ...imageStyle,
                opacity: 1,
            } as const;
        }
    },
} as const;

export function VerticalAccordion({ sectionData, headerSection, lazyLoad }: VerticalAccordionProps): JSX.Element {
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
    const { title, description, accordionItems, swapSides, accordionButtonVariant } = sectionData;

    return (
        <SectionContainer>
            <Grid container direction='column' rowGap={{ xs: 2, md: 3 }}>
                {title ? (
                    <Heading align='center' variant={headerSection ? 'h1' : 'h2'} css={classes.fullWidth}>
                        {title}
                    </Heading>
                ) : null}
                {description ? (
                    <Text align='center' css={classes.fullWidth}>
                        {description}
                    </Text>
                ) : null}
                <Grid
                    container
                    direction={swapSides ? { xs: 'row', md: 'row-reverse' } : 'row'}
                    spacing={{ xs: 2, md: 5 }}
                >
                    <Grid item md={7} xs={12}>
                        <LazyLoadContainer component={Grid} lazyload={lazyLoad} css={classes.imageContainer}>
                            {accordionItems.map((accordionItem, index) => (
                                <Picture
                                    key={index}
                                    imagePath={accordionItem.image.imagePath}
                                    alt={accordionItem.image.imageAlt}
                                    mobilePath={accordionItem.image.mobilePath}
                                    mobileAlt={accordionItem.image.mobileAlt}
                                    aspectRatio='3/2'
                                    mobileAspectRatio='3/2'
                                    lazyLoad={lazyLoad}
                                    css={classes.image(index !== activeAccordionIndex)}
                                />
                            ))}
                        </LazyLoadContainer>
                    </Grid>
                    <Grid item container direction='column' gap={1} md={5} xs={12}>
                        {accordionItems.map((accordionItem, index) => (
                            <Accordion
                                key={index}
                                expanded={activeAccordionIndex === index}
                                onChange={() => setActiveAccordionIndex(index)}
                                headingLevel={!headerSection && title ? 'h3' : 'h2'}
                            >
                                <AccordionTrigger
                                    id={`accordion-item-${index}-header`}
                                    aria-controls={`accordion-item-${index}-content`}
                                    hideIcon
                                >
                                    {accordionItem.title}
                                </AccordionTrigger>
                                <AccordionPanel>
                                    <div css={classes.accordionDetails}>
                                        <RichTextContent content={accordionItem.description} color='secondary' />
                                        {accordionItem.button.buttonText ? (
                                            <V2Button
                                                variant={accordionButtonVariant ?? 'link'}
                                                rightIcon={<ForwardIcon />}
                                                component='a'
                                                href={accordionItem.button.buttonLinkPath}
                                                target='_blank'
                                                className={accordionItem.button.buttonClassName}
                                            >
                                                {accordionItem.button.buttonText}
                                            </V2Button>
                                        ) : null}
                                    </div>
                                </AccordionPanel>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </SectionContainer>
    );
}
