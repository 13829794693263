import React from 'react';
import { Grid } from '@mui/material';
import { fourColumnImagesStyle as classes } from './styleObjects/FourColumnImages';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { FourColumnImagesContent } from '@models/section/fourColumnImageSection';
import { SectionAnimation } from '@models/section';
import { LinkImageBoxes } from './helpers/LinkImageBoxes';
import { ProfileImageBoxes } from './helpers/ProfileImageBoxes';

type FourColumnImagesProps = FourColumnImagesContent & {
    animations: SectionAnimation;
    zIndex: number;
    lazyLoad: boolean;
    headerSection: boolean;
};

export function FourColumnImages({
    imageBoxes,
    title,
    animations,
    zIndex,
    type,
    align,
    lazyLoad,
    headerSection,
}: FourColumnImagesProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    return (
        <Grid
            container
            className={animations.animationsEnabled ? 'animateContainers' : ''}
            data-testid='fourColumnImagesId'
        >
            <GridContainer
                className={
                    animations.sectionAnimation
                        ? `${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`
                        : ''
                }
                css={{ zIndex }}
            >
                {title ? (
                    <Heading
                        variant='h2'
                        component={headerSection ? 'h1' : 'h2'}
                        css={classes.titles}
                        data-testid='testTitle'
                    >
                        {title}
                    </Heading>
                ) : null}
                <ChildContainer breakSpaceValue={2} justifyContent={align} lazyLoad={lazyLoad}>
                    {type === 'links' ? (
                        <LinkImageBoxes content={imageBoxes} lazyLoad={lazyLoad} />
                    ) : (
                        <ProfileImageBoxes content={imageBoxes} lazyLoad={lazyLoad} />
                    )}
                </ChildContainer>
            </GridContainer>
        </Grid>
    );
}

FourColumnImages.defaultProps = {
    type: 'links',
    align: 'flex-start',
    imageBoxes: [
        { image: { imagePath: '', imageAlt: '' }, title: 'Lorem ipsum', linkPath: '/#', linkTarget: true },
        { image: { imagePath: '', imageAlt: '' }, title: 'Lorem ipsum', linkPath: '/#', linkTarget: true },
        { image: { imagePath: '', imageAlt: '' }, title: 'Lorem ipsum', linkPath: '/#', linkTarget: true },
    ],
    animations: {
        animationsEnabled: false,
    },
};
