import React, { useState } from 'react';
import { Grid, Collapse, Button } from '@mui/material';
import { accordionListStyles } from './styleObjects/accordionList';
import { CollapsibleQuestionAnswer } from '../CollapsibleQuestionAnswer';
import { generalFormStyle } from '../EditorFormComponents/styleObjects/GeneralFormStyle';
import { RotatingPlus } from '../IconComponents/RotatingPlus';
import { maxMobileListToShow } from '@pages/public/PageContent/listSetting';
import { keyIsEnter } from '@helpers/keyPressEventHelper';
import { Heading } from '@web-for-marketing/react-ui';
import { ClassNames } from '@emotion/react';
import { AccordionList } from '@models/section/accordionListSection';

const classes = {
    ...generalFormStyle,
    ...accordionListStyles,
};

interface CollapsibleListCategoryProps {
    items: AccordionList[];
    loadMoreText: string;
    category: string;
    id: string;
}

export function CollapsibleListCategory({
    category = '',
    items = [],
    loadMoreText,
    id,
}: CollapsibleListCategoryProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const [showMaxIndex, setShowMaxIndex] = useState(maxMobileListToShow - 1);

    function toggleCategory(): void {
        setIsOpen(!isOpen);
    }

    function loadMore(): void {
        setShowMaxIndex(showMaxIndex + maxMobileListToShow);
    }

    return (
        <Grid container direction='column' css={classes.collapsibleListCategoryContainer}>
            <Grid
                container
                aria-label={isOpen ? `Click to hide ${category}` : `Click to show ${category}`}
                onClick={toggleCategory}
                aria-controls={id}
                role='button'
                justifyContent='space-between'
                css={classes.collapsibleCategoryName}
                onKeyDown={(event) => {
                    keyIsEnter(event) && toggleCategory();
                }}
            >
                <Grid item>
                    <Heading variant='h4' component='h3' css={isOpen && classes.mobileCategoryNameOpen}>
                        {category}
                    </Heading>
                </Grid>
                <Grid item>
                    <div aria-hidden='true' css={classes.expandCollapseIconButton}>
                        <RotatingPlus open={isOpen} />
                    </div>
                </Grid>
            </Grid>
            <ClassNames>
                {({ css }) => (
                    <Collapse
                        in={isOpen}
                        id={id}
                        component='div'
                        classes={{
                            root: isOpen
                                ? css([classes.collapseContentContainer, classes.noBottomPadding])
                                : css(classes.collapseContainer),
                        }}
                        timeout={200}
                    >
                        {items.map((item, index) => {
                            return index <= showMaxIndex ? (
                                <CollapsibleQuestionAnswer
                                    key={index}
                                    id={`${item.name.split(' ').join('-')}-${index}`}
                                    showTopBorder={index > 0}
                                    question={item.name}
                                    answer={item.description}
                                />
                            ) : null;
                        })}
                        {showMaxIndex <= items.length - 1 ? (
                            <Button
                                name='loadMoreItems'
                                variant='outlined'
                                onClick={loadMore}
                                css={classes.mobileLoadMoreButton}
                            >
                                {loadMoreText}
                            </Button>
                        ) : null}
                    </Collapse>
                )}
            </ClassNames>
        </Grid>
    );
}
