import React from 'react';

export function CertificationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g>
                <g id='Layer_1'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        fill='#0078D3'
                        d='M12,3c-3.3,0-6,2.7-6,6s1,3.8,2.6,4.9c0,0,0,0,0,0,.1,0,.2.2.4.2.9.5,1.9.8,3.1.8s2.2-.3,3.1-.8c.1,0,.2-.2.4-.2,0,0,0,0,0,0,1.5-1.1,2.6-2.9,2.6-4.9,0-3.3-2.7-6-6-6ZM17,15.2c1.8-1.5,3-3.7,3-6.2,0-4.4-3.6-8-8-8S4,4.6,4,9s1.2,4.8,3,6.2v6.8c0,.3.2.7.5.9.3.2.7.2,1,0l3.6-1.8,3.6,1.8c.3.2.7.1,1,0,.3-.2.5-.5.5-.9v-6.8ZM12.2,17c1,0,1.9-.2,2.8-.6v4l-2.6-1.3c-.3-.1-.6-.1-.9,0l-2.6,1.3v-4c.9.3,1.8.6,2.8.6,0,0,.2,0,.2,0s.1,0,.2,0Z'
                    />
                </g>
            </g>
        </svg>
    );
}
