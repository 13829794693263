import React from 'react';

export function OtherIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='45'
            height='44'
            viewBox='0 0 45 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g id='star, favorite, award'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.5001 1.8335C23.2057 1.8335 23.8486 2.23843 24.1535 2.87479L28.9985 12.989L40.1554 14.454C40.8568 14.5461 41.4426 15.0335 41.6607 15.7064C41.8789 16.3794 41.6903 17.1178 41.1763 17.6038L33.0191 25.3172L35.0666 36.3318C35.1957 37.0262 34.9144 37.733 34.3435 38.1488C33.7726 38.5646 33.0135 38.6155 32.3922 38.2796L22.5001 32.9315L12.6079 38.2796C11.9866 38.6155 11.2276 38.5646 10.6566 38.1488C10.0857 37.733 9.80443 37.0262 9.93351 36.3318L11.981 25.3172L3.82377 17.6038C3.30977 17.1178 3.12126 16.3794 3.33938 15.7064C3.5575 15.0335 4.14332 14.5461 4.84471 14.454L16.0016 12.989L20.8466 2.87479C21.1515 2.23843 21.7945 1.8335 22.5001 1.8335ZM22.5001 7.91043L18.8791 15.4694C18.6112 16.0285 18.079 16.4144 17.4643 16.4951L9.14342 17.5877L15.2255 23.3389C15.6777 23.7664 15.8821 24.3942 15.7683 25.006L14.2398 33.2291L21.6282 29.2346C22.1722 28.9405 22.8279 28.9405 23.372 29.2346L30.7604 33.2291L29.2318 25.006C29.118 24.3942 29.3224 23.7664 29.7746 23.3389L35.8567 17.5877L27.5358 16.4951C26.9211 16.4144 26.3889 16.0285 26.121 15.4694L22.5001 7.91043Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
