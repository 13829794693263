import React from 'react';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { Card } from '../Cards/Card';
import { cardTypes } from '../../helpers/ObjectTypeModels/Card';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { BoxTilesContent } from '@models/section/boxTilesSection';
import { Grid } from '@mui/material';

type BoxTilesProps = BoxTilesContent & {
    lazyLoad: boolean;
};

const classes = {
    cardContainer: {
        width: '100%',
    },
};

export function BoxTiles({ cards, lazyLoad }: BoxTilesProps): JSX.Element | null {
    return cards ? (
        <GridContainer>
            <ChildContainer
                data-testid='boxTilesContainer'
                breakSpaceValue={2}
                justifyContent='space-around'
                css={classes.cardContainer}
            >
                {cards.map((tile, index) => {
                    const tileContent = {
                        title: tile.linkText,
                        linkTarget: getLinkTargetValue(tile.linkTarget),
                        image: {
                            lazyLoad: lazyLoad,
                            ...tile.image,
                            mobilePath: tile.image.mobilePath,
                        },
                    };

                    return (
                        <Grid key={index} item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                card={{
                                    ...cardTypes.CONTENT_TILE_CENTERED,
                                    content: tileContent,
                                }}
                                href={tile.linkPath}
                                data-testid={`boxTile${index}`}
                            />
                        </Grid>
                    );
                })}
            </ChildContainer>
        </GridContainer>
    ) : null;
}
