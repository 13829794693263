/* eslint-disable quotes */

const getFaqArray = (faqContents) => {
    return Array.isArray(faqContents)
        ? faqContents.map(({ description, name }) => {
            return {
                '@type': 'Question',
                name: name,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: description,
                },
            };
        })
        : {};
};

export const getFAQStructuredDataScript = (faqContents) => {
    return {
        type: 'application/ld+json',
        body: `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ${JSON.stringify(getFaqArray(faqContents))}
        }`,
    };
};
