import React from 'react';
import { Grid } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { GridContainer } from '../GeotabGridComponents';
import { ContentRow } from './helpers/ContentRow';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { getContainerStyle } from './helpers/spacingStyleHelpers';
import { SectionAnimation } from '@models/section';
import { LinkListContent } from '@models/section/linkListSection';

type LinkListProps = LinkListContent & {
    animations: SectionAnimation;
    zIndex: number;
    lazyLoad: boolean;
    headerSection: boolean;
};

export function LinkList({
    title,
    rows,
    animations,
    zIndex,
    lazyLoad,
    spacing = '8rem 0',
    headerSection,
}: LinkListProps): JSX.Element | null {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const shouldIncreaseHeadingLevel = headerSection || !title;

    if (rows && rows.length) {
        return (
            <Grid container className={animations.animationsEnabled ? 'animateContainers' : ''}>
                <GridContainer style={getContainerStyle(spacing, false, zIndex)}>
                    {title ? (
                        <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} color='primary'>
                            <ParseAbbreviations content={title} />
                        </Heading>
                    ) : null}
                    <GridContainer
                        className={
                            animations.sectionAnimation
                                ? `${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`
                                : ''
                        }
                    >
                        {rows.map((row, index) => {
                            return (
                                <ContentRow
                                    rowContent={row}
                                    lazyLoad={lazyLoad}
                                    key={index}
                                    shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                />
                            );
                        })}
                    </GridContainer>
                </GridContainer>
            </Grid>
        );
    } else {
        return null;
    }
}

LinkList.defaultProps = {
    animations: {
        animationsEnabled: false,
    },
};
