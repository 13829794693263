import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { Text, Heading, observeElementIntersect, IntersectCallback, Container } from '@web-for-marketing/react-ui';
import { AnimatedStatsSectionData } from '@models/section/animatedStatsSection';
import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export interface AnimatedStatsSectionProps {
    sectionData: AnimatedStatsSectionData;
}

const classes = {
    lightModeBackground: {
        background: v2Colors.background.light,
    },
    darkModeBackground: {
        background: v2Colors.background.dark,
        '& p': {
            color: v2Colors.core.snow,
        },
        '& h4': {
            color: v2Colors.core.snow,
        },
    },
    sectionContainer: {
        paddingTop: '10rem',
        paddingBottom: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '5.5rem',
            paddingBottom: '2.5rem',
        },
        '& p': {
            marginBottom: '1.6rem',
        },
        '& h4': {
            marginBottom: '1.6rem',
        },
        '& > *': {
            padding: '0 2%',
        },
    },
    statDivider: {
        width: '6rem',
        height: '0.5rem',
        background: v2Colors.text.activePrimary,
        borderRadius: '1rem',
        marginBottom: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '1.2rem',
        },
    },
    darkDivider: {
        background: v2Colors.core.snow,
    },
    cardDescription: {
        marginBottom: '7rem !important',
        maxWidth: '35rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '3rem !important',
        },
    },
};

export function AnimatedStatsSection({ sectionData }: AnimatedStatsSectionProps): JSX.Element {
    const animationContainer = useRef<HTMLDivElement>(null);
    const darkMode = sectionData.colorMode === 'Dark' ? true : false;

    useEffect(() => {
        if (animationContainer.current) {
            const numbers = [...animationContainer.current.querySelectorAll('.stat-value')];
            if (numbers.length) {
                observeElementIntersect(numbers, countUp as IntersectCallback);
            }
        }
    }, []);

    function countUp(element: HTMLElement): void {
        let start = 0;
        const countStatsNumber = element.getAttribute('data-count');

        if (countStatsNumber) {
            const countTo = +countStatsNumber;
            const decimalPlacesMatch = countTo.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            const decimalPlaces = decimalPlacesMatch ? decimalPlacesMatch[1]?.length ?? 0 : 0;
            const duration = 2000;
            const increment = countTo / (duration / 10);

            const timer = setInterval(function () {
                start += increment;

                if (start < countTo) {
                    element.innerText = start.toLocaleString(undefined, {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    });
                } else {
                    element.innerText = countTo.toLocaleString(undefined, {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    });
                    clearInterval(timer);
                }
            }, 10);
        }
    }

    return (
        <Grid
            css={darkMode ? classes.darkModeBackground : classes.lightModeBackground}
            data-testid='AnimatedStatsSectionTestID'
            ref={animationContainer}
        >
            <Grid container component={Container} css={classes.sectionContainer}>
                {sectionData.cards
                    ? sectionData.cards.map((card, index) => {
                        const statNumber = card.statistic;
                        return (
                            <Grid key={index} item xs={12} md={4}>
                                <div css={[classes.statDivider, darkMode ? classes.darkDivider : '']} />
                                <div aria-label={`${statNumber} ${card.unit} ${card.title}`}>
                                    <Heading variant='h1' component='p' color='primary'>
                                        <span className='stat-value' data-count={statNumber}>
                                            {statNumber}
                                        </span>
                                        <span>{card.unit}</span>
                                    </Heading>
                                    <Heading variant='h4' component='h2' color='primary'>
                                        {card.title}
                                    </Heading>
                                </div>
                                <Text variant='body1' css={classes.cardDescription}>
                                    {card.description}
                                </Text>
                            </Grid>
                        );
                    })
                    : null}
            </Grid>
        </Grid>
    );
}
