import { SectionBase, SectionButtonContent } from '@models/section';

export type StandardImageBannerSection = SectionBase<1, StandardImageBannerContent>;

export interface StandardImageBannerContent {
    alt: string;
    decoration?: boolean;
    align?: string;
    buttons?: SectionButtonContent[];
    bannerOnTop?: boolean;
    backgroundPosition?: {
        x: number;
        y: number;
    };
    customProps?: {
        headerContainer?: boolean;
        noBottomPadding?: boolean;
    };
    description?: string;
    disableGradient?: boolean;
    imagePath?: string;
    imageType?: string;
    list?: string[];
    mobileImageType?: string;
    mobileImagePath?: string;
    mobileDecoration?: boolean;
    mobileAlt?: string;
    parallax?: boolean;
    title?: string;
    displayContentBlock?: boolean;
    contentBoxBackgroundColor?: ContentBoxBackgroundColor;
}

export enum ContentBoxBackgroundColor {
    Blue = 'Blue',
    Black = 'Black',
}
