import React from 'react';

export function PlayIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width=' 74'
            height=' 74'
            viewBox=' 0 0 74 74'
            fill=' none'
            xmlns=' http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d=' M37 0.0541382C16.5656 0.0541382 0 16.5953 0 36.9998C0 57.4044 16.5656 73.9457 37 73.9457C57.4344 73.9457 74 57.4044 74 36.9998C74 16.5953 57.4344 0.0541382 37 0.0541382ZM26.6656 51.4243V21.2362L54.9388 36.3309L26.6656 51.4243Z'
                fill=' white'
            />
        </svg>
    );
}
