import React from 'react';
import { FacebookIcon } from '@icon/SocialIcons/FacebookIcon';
import { InstagramIcon } from '@icon/SocialIcons/InstagramIcon';
import { LinkedInIcon } from '@icon/SocialIcons/LinkedInIcon';
import { TwitterIcon } from '@icon/SocialIcons/TwitterIcon';
import { MailIcon } from '@web-for-marketing/react-ui';

interface EditorSocialLinkProps {
    socialIconType: 'facebook' | 'instagram' | 'linkedIn' | 'twitter' | 'mail';
}

const getSocialIconComponent = (
    socialIconType: 'facebook' | 'instagram' | 'linkedIn' | 'twitter' | 'mail'
): JSX.Element | null => {
    switch (socialIconType) {
        case 'facebook':
            return <FacebookIcon />;
        case 'instagram':
            return <InstagramIcon />;
        case 'linkedIn':
            return <LinkedInIcon />;
        case 'twitter':
            return <TwitterIcon />;
        case 'mail':
            return <MailIcon />;
        default:
            return null;
    }
};

export function SocialIcon({ socialIconType }: EditorSocialLinkProps): JSX.Element | null {
    return getSocialIconComponent(socialIconType);
}
