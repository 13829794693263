import { SectionColumn } from '@models/column';

export const sideTypes: { value: SectionColumn['type']; content: string }[] = [
    {
        value: 'title-body-buttons',
        content: 'Title, Body & Buttons',
    },
    {
        value: 'layered-content',
        content: 'Layered Content',
    },
    {
        value: 'image',
        content: 'Image',
    },
    {
        value: 'video',
        content: 'Video',
    },
    {
        value: 'cards',
        content: 'Cards',
    },
    {
        value: 'html',
        content: 'Raw HTML',
    },
    {
        value: 'testimonial',
        content: 'Testimonial',
    },
    {
        value: 'cta-banner-carousel',
        content: 'Call To Action Banner Carousel',
    },
    {
        value: 'countdown',
        content: 'Countdown',
    },
    {
        value: 'custom-section',
        content: 'Custom Section',
    },
    {
        value: 'custom-form',
        content: 'Custom Form',
    },
    {
        value: 'none',
        content: 'None',
    },
];

export const buttonTypes = [
    {
        value: 'outlined',
        content: 'Outlined',
    },
    {
        value: 'contained',
        content: 'Filled',
    },
    {
        value: 'text',
        content: 'Text',
    },
];

export const mediaSizes = [
    {
        value: 'small',
        content: 'Small',
    },
    {
        value: 'medium',
        content: 'Medium',
    },

    {
        value: 'large',
        content: 'Large',
    },
];

export const mediaSources = [
    {
        value: 'gallery',
        content: 'Gallery',
    },
    {
        value: 'external',
        content: 'External',
    },
];

export const spacingTypes = [
    {
        value: '0rem 0',
        content: 'None',
    },
    {
        value: '2rem 0',
        content: 'Smallest',
    },
    {
        value: '4rem 0',
        content: 'Smaller',
    },
    {
        value: '6rem 0',
        content: 'Small',
    },
    {
        value: '8rem 0',
        content: 'Regular',
    },
    {
        value: '10rem 0',
        content: 'Large',
    },
    {
        value: '8rem 0 0',
        content: 'Just Top',
    },
    {
        value: '0 0 8rem',
        content: 'Just Bottom',
    },
];

export const spacingAsSplitValues = {
    '0rem 0': { paddingTop: 0, paddingBottom: 0 },
    '2rem 0': { paddingTop: 2, paddingBottom: 2 },
    '4rem 0': { paddingTop: 4, paddingBottom: 4 },
    '6rem 0': { paddingTop: 6, paddingBottom: 6 },
    '8rem 0': { paddingTop: 8, paddingBottom: 8 },
    '10rem 0': { paddingTop: 10, paddingBottom: 10 },
    '8rem 0 0': { paddingTop: 8, paddingBottom: 0 },
    '0 0 8rem': { paddingTop: 0, paddingBottom: 8 },
};

export const alignTypes = [
    {
        value: 'flex-start',
        name: 'Top',
    },
    {
        value: 'center',
        name: 'Center',
    },
    {
        value: 'flex-end',
        name: 'Bottom',
    },
];

export const xAlignTypes = [
    {
        value: 'flex-start',
        name: 'Left',
    },
    {
        value: 'center',
        name: 'Center',
    },
    {
        value: 'flex-end',
        name: 'Right',
    },
] as const;

export const textAlignTypes = [
    {
        value: 'left',
        name: 'Left',
    },
    {
        value: 'center',
        name: 'Center',
    },
    {
        value: 'right',
        name: 'Right',
    },
] as const;

export const galleryFormType = [
    {
        value: 'image-title-link-cards',
        name: 'Image Title Link Cards',
    },
    {
        value: 'customizable-image-gallery',
        name: 'Customizable Image Gallery',
    },
];
