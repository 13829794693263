import React from 'react';

export function LinkedInIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            width='20'
            height='20'
            color='#fff'
            {...props}
        >
            <path
                className='linkedin-icon'
                fill='currentColor'
                d='M4,18H.3V6H4ZM2.16,4.34A2.17,2.17,0,0,1,2.16,0a2.17,2.17,0,0,1,0,4.34ZM18,18H14.27V12.15c0-1.39,0-3.18-1.94-3.18s-2.24,1.51-2.24,3.08V18H6.37V6H10V7.62H10a3.92,3.92,0,0,1,3.53-1.94C17.31,5.68,18,8.17,18,11.4V18Z'
            />
        </svg>
    );
}
