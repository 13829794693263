import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { AccordionListDesktop } from './AccordionListDesktop';
import { AccordionListMobile } from './AccordionListMobile';
import { AccordionListContent } from '@models/section/accordionListSection';

type AccordionListProps = AccordionListContent & {
    zIndex: number;
    headerSection: boolean;
};

function getUniqueCategories(lists: AccordionListContent['list'], allListCategory: string): string[] {
    return lists ? [allListCategory, ...new Set(lists.map((p) => p.category))] : [allListCategory];
}

export function AccordionList({ list, settings, zIndex, title, headerSection }: AccordionListProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const allListCategory = settings['allFilter'] || 'All';
    const loadMoreText = settings['loadMoreText'] || 'Load more';
    const categories = useMemo(() => {
        return getUniqueCategories(list, allListCategory);
    }, [list, allListCategory]);

    return (
        <>
            {settings['disableFilter'] ? (
                <AccordionListDesktop
                    title={title}
                    lists={list}
                    categories={categories}
                    disableFilter={settings['disableFilter']}
                    allFilter={allListCategory}
                    enableStructuredData={settings['enableStructuredData']}
                    zIndex={zIndex}
                    headerSection={headerSection}
                />
            ) : !isMobile ? (
                <AccordionListDesktop
                    title={title}
                    lists={list}
                    categories={categories}
                    disableFilter={settings['disableFilter']}
                    allFilter={allListCategory}
                    enableStructuredData={settings['enableStructuredData']}
                    zIndex={zIndex}
                    headerSection={headerSection}
                />
            ) : (
                <AccordionListMobile
                    title={title}
                    lists={list}
                    categories={categories}
                    zIndex={zIndex}
                    enableStructuredData={settings['enableStructuredData']}
                    allFilter={allListCategory}
                    loadMoreText={loadMoreText}
                />
            )}
        </>
    );
}
