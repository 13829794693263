import React, { useEffect, useState, useMemo } from 'react';
import { Grid, List, ListItem } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { standardImageBannerStyle as classes } from './styleObjects/StandardImageBanner';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { GridContainer } from '../GeotabGridComponents';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { Picture } from '../Picture';
import { LazyLoadContainer, Heading } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { VideoTag } from '@components/VideoTag';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { mediaTypes } from '@helpers/gallery';
import { ClassNames } from '@emotion/react';
import { breakpoints } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { SectionAnimation } from '@models/section';
import { StandardImageBannerContent, ContentBoxBackgroundColor } from '@models/section/standardImageBannerSection';
import { isPreviewingAnimationAtom } from '@atoms/animation';

export type StandardImageBannerProps = StandardImageBannerContent & {
    children?: React.ReactNode;
    lazyLoad?: boolean;
    zIndex?: number;
    animations: SectionAnimation;
    headerBanner?: boolean;
};

export function StandardImageBanner({
    children,
    buttons,
    headerBanner,
    title,
    description,
    customProps,
    list,
    imageType,
    mobileImageType,
    align,
    animations,
    zIndex,
    imagePath,
    mobileImagePath,
    alt,
    parallax,
    backgroundPosition,
    disableGradient,
    lazyLoad,
    bannerOnTop,
    displayContentBlock = false,
    contentBoxBackgroundColor = ContentBoxBackgroundColor.Blue,
    mobileAlt,
}: StandardImageBannerProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const { prefixStringWithBaseUrl } = useStaticValues();
    const [reducedMotion, setReducedMotion] = useState(false);
    const imageUrl = useMemo(
        () =>
            mobileImagePath && isMobile ? prefixStringWithBaseUrl(mobileImagePath) : prefixStringWithBaseUrl(imagePath),
        [imagePath, mobileImagePath, isMobile, prefixStringWithBaseUrl]
    );
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    useEffect(() => {
        setReducedMotion(window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches);
    }, [setReducedMotion]);

    function getContainerBg(): { backgroundImage: string; backgroundPosition: string; backgroundAttachment: string } {
        if (disableGradient || displayContentBlock) {
            return classes.noGradientBg(imageUrl, backgroundPosition, reducedMotion, parallax);
        } else {
            if (isMobile) {
                return classes.mobileGradientImageBg(imageUrl, backgroundPosition, reducedMotion, parallax);
            } else {
                return classes.gradientImageBg(imageUrl, align, parallax, backgroundPosition, reducedMotion);
            }
        }
    }

    const mediaType =
        (!isMobile && imageType === mediaTypes.video) || (isMobile && mobileImageType === mediaTypes.video)
            ? 'video'
            : 'image';

    return (
        <ClassNames>
            {({ css }) => (
                <LazyLoadContainer
                    component={Grid}
                    lazyload={lazyLoad}
                    lazyClass={!reducedMotion && parallax ? css(getContainerBg()) : ''}
                    container
                    data-testid='banner-container'
                    css={[
                        classes.parentContainer(displayContentBlock),
                        bannerOnTop ? undefined : classes.setUnder,
                        classes.defaultBg,
                        {
                            zIndex: zIndex,
                            textAlign: align === 'center' ? align : 'left',
                        },
                    ]}
                    className={animations.animationsEnabled ? 'animateContainers' : ''}
                >
                    {!lazyLoad && mediaType === mediaTypes.image ? (
                        <PageHelmetPreloadLink as='image' href={imageUrl} />
                    ) : null}
                    {mediaType === mediaTypes.video ? (
                        <VideoTag
                            isMobile={isMobile}
                            mobileVideoPath={mobileImagePath}
                            videoPath={imagePath}
                            autoPlay={true}
                            lazyLoad={lazyLoad}
                            css={classes.image}
                        />
                    ) : (
                        <Picture
                            lazyLoad={lazyLoad}
                            css={[
                                classes.image,
                                !reducedMotion && parallax ? classes.hideImage : undefined,
                                backgroundPosition ? classes.picturePosition(backgroundPosition) : undefined,
                            ]}
                            mobileQuery={`${breakpoints.md}px`}
                            mobilePath={mobileImagePath}
                            mobileAlt={mobileAlt}
                            imagePath={imagePath}
                            alt={alt}
                        />
                    )}
                    <Grid
                        css={[
                            classes.image,
                            classes.gradient,
                            disableGradient || (!reducedMotion && parallax) ? classes.hideImage : undefined,
                            !disableGradient && isMobile
                                ? classes.mobileGradientBg(contentBoxBackgroundColor, displayContentBlock)
                                : undefined,
                            !disableGradient && !displayContentBlock && !isMobile
                                ? classes.gradientBg(align)
                                : undefined,
                        ]}
                        data-testid='gradient-overlay'
                    />
                    <GridContainer
                        alignItems={align === 'center' ? align : 'flex-start'}
                        justifyContent={align}
                        css={[
                            classes.mainContainer,
                            headerBanner ? classes.headerBannerContainer : undefined,
                            customProps?.headerContainer ? classes.officeHeaderContainer : undefined,
                            customProps?.noBottomPadding ? classes.noBottomPadding : undefined,
                        ]}
                        className={`${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`}
                        direction='row'
                        data-testid='text-container'
                    >
                        <Grid
                            item
                            data-testid='box-container'
                            css={[
                                classes.contentContainer,
                                displayContentBlock && !isMobile
                                    ? classes.headerContentBlock(contentBoxBackgroundColor)
                                    : undefined,
                            ]}
                            xs={12}
                            md={8}
                            lg={6}
                        >
                            {title ? (
                                <Heading
                                    variant={headerBanner ? 'h1' : 'h2'}
                                    color='inversePrimary'
                                    css={align === 'center' ? classes.autoMargin : undefined}
                                    className={`${getAnimationStyle(
                                        'titleAnimation',
                                        true,
                                        animations,
                                        isPreviewingAnimation
                                    )}`}
                                >
                                    <ParseAbbreviations content={title} />
                                </Heading>
                            ) : null}
                            {description ? (
                                <RichTextContent
                                    variant={headerBanner ? 'h3' : 'body1'}
                                    weight='regular'
                                    color='inversePrimary'
                                    css={[
                                        classes.descriptionTypography,
                                        align === 'center' ? classes.autoMargin : undefined,
                                    ]}
                                    className={`${getAnimationStyle(
                                        'descriptionAnimation',
                                        true,
                                        animations,
                                        isPreviewingAnimation
                                    )}`}
                                    data-testid='banner-description'
                                    content={description}
                                />
                            ) : null}
                            {list && list.length ? (
                                <List
                                    dense
                                    css={classes.lists}
                                    className={`${getAnimationStyle(
                                        'listAnimation',
                                        true,
                                        animations,
                                        isPreviewingAnimation
                                    )}`}
                                >
                                    {list.map((value, index) => {
                                        return (
                                            <ListItem css={classes.listItems} key={index}>
                                                <ParseAbbreviations content={value} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            ) : null}
                            {buttons
                                ? buttons.map(
                                    (
                                        {
                                            buttonText,
                                            buttonLinkPath,
                                            buttonType,
                                            buttonTarget,
                                            buttonClassName,
                                            ariaLabel,
                                            isPopout,
                                            popoutCustomSection,
                                            params = [],
                                        },
                                        index
                                    ) => {
                                        if (isPopout) {
                                            return (
                                                <PopoutButton
                                                    key={index}
                                                    text={buttonText}
                                                    color='secondary'
                                                    variant={buttonType || 'contained'}
                                                    customSectionKey={popoutCustomSection}
                                                    aria-label={ariaLabel}
                                                    params={params}
                                                    css={classes.buttonItem}
                                                    className={`${getAnimationStyle(
                                                        'buttonAnimation',
                                                        true,
                                                        animations,
                                                        isPreviewingAnimation
                                                    )}`}
                                                    classes={{
                                                        root: `${buttonClassName ? buttonClassName : ''}`,
                                                    }}
                                                />
                                            );
                                        } else {
                                            return (
                                                <CMSLink
                                                    key={index}
                                                    variant={buttonType || 'contained'}
                                                    color='secondary'
                                                    href={buttonLinkPath}
                                                    aria-label={ariaLabel}
                                                    target={getLinkTargetValue(buttonTarget)}
                                                    css={classes.buttonItem}
                                                    className={`${getAnimationStyle(
                                                        'buttonAnimation',
                                                        true,
                                                        animations,
                                                        isPreviewingAnimation
                                                    )}`}
                                                    type='button'
                                                    buttonClassName={buttonClassName}
                                                >
                                                    {buttonText}
                                                </CMSLink>
                                            );
                                        }
                                    }
                                )
                                : null}
                        </Grid>
                        {children ? (
                            <Grid
                                container
                                direction='row'
                                alignContent={align === 'center' ? 'center' : 'flex-start'}
                                justifyContent='flex-start'
                            >
                                {children}
                            </Grid>
                        ) : null}
                    </GridContainer>
                </LazyLoadContainer>
            )}
        </ClassNames>
    );
}

StandardImageBanner.defaultProps = {
    align: 'flex-start',
    button: {},
    customProps: {},
    animations: {
        animationsEnabled: false,
    },
};
