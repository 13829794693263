import React from 'react';
import { Grid } from '@mui/material';
import { GridContainer } from '../GeotabGridComponents';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { EventTile } from './EventTile';
import { Heading } from '@web-for-marketing/react-ui';
import { EventSectionContent } from '@models/section/eventsSection';
import { SectionAnimation } from '@models/section';

const classes = {
    eventsContainer: {
        padding: 0,
    },
    eventTile: {
        paddingBottom: '8rem',
        borderBottom: 'solid 1px #CFD5DC',
        '&:not(:first-of-type)': {
            paddingTop: '8rem',
        },
    },
    noBorderPadBottom: {
        '& > *:last-child': {
            paddingBottom: '0',
            borderBottom: 'unset',
        },
    },
    containerUnpaddedBottom: {
        paddingBottom: '0',
    },
};

type EventsSectionsProps = EventSectionContent & {
    zIndex: number;
    lazyLoad: boolean;
    animations: SectionAnimation;
    headerSection: boolean;
};

export function EventsSections({
    title,
    events,
    animations,
    openBottom,
    zIndex,
    lazyLoad,
    headerSection,
}: EventsSectionsProps): JSX.Element {
    const shouldIncreaseHeadingLevel = headerSection || !title;
    return (
        <Grid data-testid='eventSection' container className={animations ? 'animateContainers' : ''}>
            <GridContainer css={[openBottom ? classes.containerUnpaddedBottom : '', { zIndex }]}>
                {title ? (
                    <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} color='primary'>
                        <ParseAbbreviations content={title} />
                    </Heading>
                ) : null}
                <Grid
                    container
                    direction='column'
                    css={[!openBottom ? classes.noBorderPadBottom : '', classes.eventsContainer]}
                >
                    {events.map((eventTile, index) => {
                        return (
                            <EventTile
                                css={classes.eventTile}
                                key={`${index}-${eventTile.title}`}
                                order={index}
                                lazyLoad={lazyLoad}
                                shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                {...eventTile}
                            />
                        );
                    })}
                </Grid>
            </GridContainer>
        </Grid>
    );
}

EventsSections.defaultProps = {
    animations: {
        animationsEnabled: false,
    },
    openBottom: true,
    events: [],
    customProps: {},
};
