import { ComparisonItem, DeviceType } from '@models/comparisonItem';
import { httpGet } from './apiService';

const deviceComparisonBaseApiUrl = '/api/device-comparison';

export async function getAllComparisonItemNames(type: DeviceType): Promise<string[]> {
    return (await httpGet(`${deviceComparisonBaseApiUrl}/names?type=${type}`)).json();
}

export async function getComparisonItemByNames(names: string[], type: DeviceType): Promise<ComparisonItem[]> {
    const queryString = names
        .map((name) => `names=${encodeURIComponent(name)}`)
        .join('&')
        .concat(`&type=${type}`);
    return (await httpGet(`${deviceComparisonBaseApiUrl}/by-names?${queryString}`)).json();
}
