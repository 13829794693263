import React, { useState, useRef } from 'react';
import { FeatureAccordionSectionData } from '@models/section/featureAccordionSection';
import { Grid, Tab, Tabs, TabsActions } from '@mui/material';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { RichTextContent } from '@components/RichTextContent';
import { CMSLink } from '@components/CMSLink';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { Picture } from '@components/Picture';
import { breakpoints, overlays, v2Colors } from '@web-for-marketing/react-ui';
import { Heading, LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { ParseAbbreviations } from '../ParseAbbreviations';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { MediaType } from './helpers/MediaType';
import { SectionContainer } from './SectionContainer';

export interface FeatureAccordionProps {
    sectionData: FeatureAccordionSectionData;
    lazyLoad: boolean;
}

const classes = {
    tabContainer: {
        margin: '4rem 0',
        maxWidth: '100%',
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginRight: '-3.6rem',
            marginLeft: '-3.6rem',
            maxWidth: 'calc(100% + 7.2rem)',
        },
    },
    tab: (darkMode?: boolean) =>
        ({
            '&.MuiTab-root': {
                textTransform: 'none',
                color: v2Colors.text.primary,
                fontWeight: 400,
                fontSize: '2rem !important',
                borderRadius: '8px',
                padding: '1.6rem 3.2rem',
                border: '1px solid transparent',
                transition:
                    'background-color 500ms ease, border-color 500ms ease, color 500ms ease, font-weight 500ms ease',
                '&:not(:last-child)': {
                    marginRight: '2rem',
                },
                '&:hover': {
                    color: darkMode ? v2Colors.text.primary : v2Colors.text.activeSecondary,
                    fontWeight: 500,
                    background: darkMode ? overlays.active : overlays.activeSecondary,
                    border: `1px solid ${v2Colors.action.active}`,
                },
                [`@media (max-width: ${breakpoints.md}px)`]: {
                    fontSize: '1.6rem !important',
                    border: `1px solid ${v2Colors.border.primary}`,
                    '&:not(:last-child)': {
                        marginRight: '1.6rem',
                    },
                },
            },
            '&.Mui-selected': {
                color: darkMode ? v2Colors.text.primary : v2Colors.text.activeSecondary,
                fontWeight: 500,
                background: darkMode ? overlays.active : overlays.activeSecondary,
                border: `1px solid ${v2Colors.action.active}`,
            },
        }) as const,
    imageMedia: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
    },
    button: {
        '&.MuiButton-root': {
            display: 'inline-flex',
            alignItems: 'center',
            width: 'auto',
            [`@media (max-width: ${breakpoints.md}px)`]: {
                width: '100%',
            },
        },
    },
    contentContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            order: 2,
        },
    },
    fullWidth: {
        width: '100%',
    },
    description: {
        marginTop: '2rem',
    },
} as const;

export function FeatureAccordion({ sectionData, lazyLoad }: FeatureAccordionProps): JSX.Element | null {
    const [isMobile] = useAtom(isMobileAtom);
    const [tab, setTab] = useState(0);
    const tabsRef = useRef<TabsActions>(null);
    const { title, description, featureTabs, darkOverlay } = sectionData;

    if (tab > featureTabs.length - 1) {
        setTab((prevTab) => prevTab - 1);
        tabsRef?.current?.updateIndicator();
    }

    function switchTabs(_event: React.SyntheticEvent, newTab: number): void {
        setTab(newTab);
    }

    function changeIndex(newTab: number): void {
        setTab(newTab);
    }

    return (
        <div
            data-testid='FeatureAccordionOverlay'
            css={darkOverlay ? { backgroundColor: v2Colors.background.light } : undefined}
        >
            <SectionContainer data-testid='FeatureAccordionTestID'>
                <Grid container direction='column' alignItems='center'>
                    <Heading align='center' variant='h2' css={classes.fullWidth}>
                        {title}
                    </Heading>
                    {description ? (
                        <Text align='center' css={[classes.description, classes.fullWidth]}>
                            {description}
                        </Text>
                    ) : null}
                    <Tabs
                        action={tabsRef}
                        value={tab}
                        onChange={switchTabs}
                        css={classes.tabContainer}
                        variant='scrollable'
                        scrollButtons={isMobile ? false : 'auto'}
                    >
                        {featureTabs.map((featureTab, index) => (
                            <Tab
                                key={index}
                                label={featureTab.title}
                                id={`feature-accordion-tab-${index}`}
                                aria-controls={`feature-accordion-tabpanel-${index}`}
                                css={classes.tab(darkOverlay)}
                                wrapped
                            />
                        ))}
                    </Tabs>
                    <SwipeableViews
                        css={classes.fullWidth}
                        index={tab}
                        onChangeIndex={changeIndex}
                        enableMouseEvents
                        springConfig={{
                            duration: '0.5s',
                            easeFunction: 'ease-out',
                            delay: '0s',
                        }}
                    >
                        {featureTabs.map(({ title, heading, description, buttons, media }, index) => {
                            const isHidden = tab !== index;
                            return (
                                <div
                                    key={`${index}-${JSON.stringify(media)}`}
                                    role='tabpanel'
                                    data-testid={`tabpanel-${index}`}
                                    css={{ padding: '0 1rem' }}
                                    id={`feature-accordion-tabpanel-${index}`}
                                    aria-labelledby={`feature-accordion-tab-${index}`}
                                >
                                    <LazyLoadContainer
                                        component={Grid}
                                        container
                                        alignItems='center'
                                        columnSpacing={6}
                                        rowSpacing={4}
                                        lazyload={lazyLoad}
                                    >
                                        <Grid item xs={12} md={6} css={classes.contentContainer}>
                                            <Heading variant='h4' component='h3' css={{ marginBottom: '2rem' }}>
                                                {heading ? heading : title}
                                            </Heading>
                                            <RichTextContent
                                                content={
                                                    ParseAbbreviations({ content: description, stringFormat: true }) ??
                                                    ''
                                                }
                                                hideLinks={isHidden}
                                                css={{ marginBottom: '2.5rem' }}
                                            />
                                            {buttons.length ? (
                                                <Grid container direction='row' gap={2}>
                                                    {buttons.map((button, index) =>
                                                        button.isPopout ? (
                                                            <PopoutButton
                                                                key={`${button.buttonText}-${index}`}
                                                                text={button.buttonText}
                                                                variant={button.buttonType || 'contained'}
                                                                customSectionKey={button.popoutCustomSection}
                                                                aria-label={button.ariaLabel}
                                                                css={classes.button}
                                                                params={button.params}
                                                                tabIndex={isHidden ? -1 : 0}
                                                            />
                                                        ) : (
                                                            <CMSLink
                                                                key={`${button.buttonText}-${index}`}
                                                                variant={button.buttonType || 'contained'}
                                                                href={button.buttonLinkPath}
                                                                target={getLinkTargetValue(button.buttonTarget)}
                                                                aria-label={button.ariaLabel}
                                                                type='button'
                                                                css={classes.button}
                                                                tabIndex={isHidden ? -1 : 0}
                                                            >
                                                                {button.buttonText}
                                                            </CMSLink>
                                                        )
                                                    )}
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {media.type === 'video' ? (
                                                <MediaType content={media.content} lazyLoad={lazyLoad} />
                                            ) : media.type === 'image' ? (
                                                <Picture
                                                    imagePath={media.content.imagePath}
                                                    mobilePath={media.content.mobilePath}
                                                    alt={media.content.imageAlt}
                                                    mobileAlt={media.content.mobileAlt}
                                                    css={classes.imageMedia}
                                                    lazyLoad={lazyLoad}
                                                    fullWidth
                                                    preload={index === 0 && !lazyLoad}
                                                    aspectRatio={media.content.imageAspectRatio}
                                                    mobileAspectRatio={media.content.mobileImageAspectRatio}
                                                />
                                            ) : null}
                                        </Grid>
                                    </LazyLoadContainer>
                                </div>
                            );
                        })}
                    </SwipeableViews>
                </Grid>
            </SectionContainer>
        </div>
    );
}
