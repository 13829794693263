import React from 'react';

export function MarketplaceIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            width='36'
            height='24'
            viewBox='0 0 36 24'
            fill='none'
            {...props}
        >
            <path d='M12.8425 0.0156567L0 20.235L5.81519 23.9937L18.6577 3.77436L12.8425 0.0156567Z' fill='#0078D3' />
            <path d='M26.1606 0.0220058L13.3181 20.2413L19.1333 24L31.9758 3.78071L26.1606 0.0220058Z' fill='#00AEFF' />
            <path
                d='M31.9859 3.75883C30.9416 5.4108 30.372 7.35007 30.372 9.33722C30.372 12.8806 32.1283 16.1366 35.095 18.052L31.3451 23.9177C26.4086 20.7095 23.4419 15.2508 23.4419 9.33722C23.4419 6.00934 24.3675 2.77722 26.1475 0'
                fill='#00AA00'
            />
            <path
                d='M26.1713 0C24.3913 2.77723 23.4657 6.00934 23.4657 9.33722C23.4657 11.468 23.8454 13.527 24.5574 15.4423L31.9859 3.78278L26.1713 0Z'
                fill='#25477B'
            />
        </svg>
    );
}
