export function getAnimationStyle(animationNameKey, animationTrigger, animations, isPreviewingAnimation, animation) {
    if (
        animations &&
        animations.animationsEnabled &&
        (innerContentIsAnimating(animations) || animationNameKey === 'sectionAnimation')
    ) {
        let animationClasses = [];

        animationClasses[0] = 'toAnimate';
        animationClasses[1] = animationTrigger ? 'toAnimateTrigger' : '';
        animationClasses[2] = animation ? animation : animations[animationNameKey] || 'none';
        animationClasses[3] = isPreviewingAnimation ? 'animatePreview' : '';
        animationClasses = animationClasses.join(' ');

        return animationClasses;
    } else {
        return '';
    }
}

function innerContentIsAnimating(animations) {
    return typeof animations.sectionAnimation === 'undefined' || animations.sectionAnimation === 'none';
}
