import React from 'react';
import { Grid, Card } from '@mui/material';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { shortStackThreeColumnCardStyles as classes } from '../Home/styleObjects/ShortStackThreeColumnCardStyles';
import { Picture } from '../Picture';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { ClassNames } from '@emotion/react';
import { TextAlignValues, XAlignValues } from '@models/editorForm';
import { SectionAnimation } from '@models/section';
import { ShortStackCards } from '@models/section/threeColumnCardsSection';

interface ShortStackThreeColumnCardProps {
    lazyLoad: boolean;
    align: XAlignValues;
    title: string;
    textAlign?: TextAlignValues;
    zIndex: number;
    animations: SectionAnimation;
    cards: ShortStackCards['cards'];
    headerSection?: boolean;
}

export function ShortStackThreeColumnCard({
    cards,
    title,
    animations,
    zIndex,
    align,
    textAlign,
    lazyLoad,
    headerSection,
}: ShortStackThreeColumnCardProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);

    return (
        <Grid data-testid='shortStackCard' item container className={animations ? 'animateContainers' : ''} md={12}>
            <GridContainer
                className={`${
                    animations.sectionAnimation
                        ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                        : ''
                }`}
                style={{ zIndex: zIndex }}
                direction='column'
            >
                <Grid item>
                    {title ? (
                        <Heading
                            variant='h2'
                            component={headerSection ? 'h1' : 'h2'}
                            css={classes.titles}
                            style={{ textAlign, marginLeft: textAlign === 'left' ? 0 : 'auto' }}
                            data-testid='mainTitle'
                        >
                            {title}
                        </Heading>
                    ) : null}
                </Grid>
                <ChildContainer
                    justifyContent={align}
                    breakSpaceValue={1}
                    lazyLoad={lazyLoad}
                    direction='row'
                    css={classes.cardParentContainer}
                    data-testid='cardArray'
                >
                    {cards.length && Array.isArray(cards)
                        ? cards.map(({ image, title, body }, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={index}
                                    css={classes.cardContainer}
                                    className={`${getAnimationStyle(
                                          `cardsAnimation${index}`,
                                          index === 0,
                                          animations,
                                          isPreviewingAnimation
                                    )}`}
                                >
                                    <ClassNames>
                                        {({ css }) => (
                                            <Card css={classes.card}>
                                                <Grid item>
                                                    <Text color='activePrimary' css={classes.cardTitle}>
                                                        <ParseAbbreviations content={title} />
                                                    </Text>
                                                    <Text color='secondary' css={classes.cardDivider}></Text>
                                                    <RichTextContent
                                                        content={
                                                            body
                                                                ? ParseAbbreviations({
                                                                    content: body,
                                                                    stringFormat: true,
                                                                })
                                                                : ''
                                                        }
                                                        css={classes.cardBody}
                                                    />
                                                </Grid>
                                                <Picture
                                                    imagePath={image.imagePath}
                                                    mobilePath={image.mobilePath}
                                                    alt={image.imageAlt || ''}
                                                    mobileAlt={image.mobileAlt}
                                                    css={classes.media}
                                                    pictureProps={{ className: css(classes.pictureContainer) }}
                                                    lazyLoad={lazyLoad}
                                                />
                                            </Card>
                                        )}
                                    </ClassNames>
                                </Grid>
                            );
                        })
                        : null}
                </ChildContainer>
            </GridContainer>
        </Grid>
    );
}

ShortStackThreeColumnCard.defaultProps = {
    cards: [
        { title: '', body: '', linkPath: '', linkText: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
        { title: '', body: '', linkPath: '', linkText: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
        { title: '', body: '', linkPath: '', linkText: '', linkTarget: true, image: { imageAlt: '', imagePath: '' } },
    ],
    align: 'flex-start',
    textAlign: 'center',
    animations: {
        animationsEnabled: false,
    },
};
