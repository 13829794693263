import React from 'react';
import { IconButton } from '@mui/material';
import { breakpoints } from '@web-for-marketing/react-ui';

const classes = {
    deleteButton: {
        position: 'absolute',
        top: '4rem',
        zIndex: 10,
        right: '4rem',
        padding: '1.5rem !important',
        '& svg': {
            height: '3rem',
            width: '3rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            position: 'static',
            float: 'right',
            marginBottom: '2rem',
            '& svg': {
                height: '2.4rem',
                width: '2.4rem',
            },
        },
    },
} as const;

interface ClosePopoutButtonProp {
    closeWindow: () => void;
}

export function ClosePopoutButton({ closeWindow }: ClosePopoutButtonProp): JSX.Element {
    return (
        <IconButton aria-label='Close dialog window' css={classes.deleteButton} onClick={closeWindow} size='large'>
            <svg
                aria-hidden
                xmlns='http://www.w3.org/2000/svg'
                data-name='Close icon'
                viewBox='0 0 36 36'
                width='1rem'
                height='1rem'
            >
                <defs>
                    <style>
                        {
                            '.cls-123{fill: none; stroke:#0078D3; stroke-linecap:round; stroke-linejoin:round; stroke-width:4px}'
                        }
                    </style>
                </defs>
                <line className='cls-123' x1='33.5' y1='2.5' x2='2.5' y2='33.5' />
                <line className='cls-123' x1='33.5' y1='33.5' x2='2.5' y2='2.5' />
            </svg>
        </IconButton>
    );
}
