import React, { useState } from 'react';
import { fourColumnImagesStyle as classes } from '../styleObjects/FourColumnImages';
import { keyIsEnter, keyIsSpace } from '@helpers/keyPressEventHelper';
import { Card } from '../../Cards/Card';
import { cardTypes } from '../../../helpers/ObjectTypeModels/Card';
import { ImagePopout } from '../../ImagePopout/ImagePopout';
import { ClassNames } from '@emotion/react';
import { ProfileImageBox } from '@models/section/fourColumnImageSection';
import { Grid } from '@mui/material';

interface ProfileImageBoxesProps {
    content: ProfileImageBox[];
    lazyLoad: boolean;
}

export function ProfileImageBoxes({ content, lazyLoad }: ProfileImageBoxesProps): JSX.Element {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    function handleKeydownOnProfile(event: React.KeyboardEvent, index: number): void {
        if (keyIsEnter(event) || keyIsSpace(event)) {
            event.preventDefault();
            setOpenIndex(index);
        }
    }

    return (
        <>
            {content.map((card, index) => {
                const tileContent = {
                    title: card.name,
                    body: card.title,
                    image: { lazyLoad, ...card.image },
                };

                return (
                    <ClassNames key={index}>
                        {({ css }) => (
                            <Grid item xs={12} md={6} lg={3}>
                                <Card
                                    card={{
                                        ...cardTypes.DEFAULT_FOUR_COLUMN,
                                        content: tileContent,
                                    }}
                                    onClick={() => setOpenIndex(index)}
                                    onKeyDown={(event: React.KeyboardEvent) => handleKeydownOnProfile(event, index)}
                                    data-testid={`profileId${index}`}
                                    aria-label={`View full popup profile for ${card.name}`}
                                    role='button'
                                    tabIndex={0}
                                    wrapperClass={css(classes.cardButtonWrapper)}
                                />
                            </Grid>
                        )}
                    </ClassNames>
                );
            })}
            <ImagePopout
                openIndex={openIndex}
                imageBoxes={content}
                onClose={() => setOpenIndex(null)}
                onIncrementIndex={(increment) => setOpenIndex((value) => (value !== null ? value + increment : value))}
            />
        </>
    );
}
