import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const fourColumnImagesStyle = {
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        margin: '0 auto 3rem',
    },
    profileNames: {
        color: v2Colors.core.geotab,
    },
    cardButtonWrapper: {
        padding: 0,
        textAlign: 'left',
    },
    linkContainer: {
        height: '100%',
        width: '100%',
        '& h3': {
            transition: 'color 300ms ease',
        },
        '&:hover h3': {
            textDecoration: 'underline',
        },
        '&:focus': {
            outline: 'none',
            '& h3': {
                textDecoration: 'underline',
                backgroundColor: v2Colors.background.light,
                borderRadius: '8px',
                padding: '0.4rem 0.8rem',
            },
        },
    },
    linkText: {
        color: v2Colors.text.activePrimary,
    },
    cardContainer: {
        minWidth: 200,
        padding: '2.7rem 2%',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            maxWidth: '45rem',
            marginBottom: '3rem',
            margin: 'auto',
        },
    },
    contentContainer: {
        padding: '2rem',
    },
    media: {
        height: 'auto',
        width: '100%',
        marginBottom: '3rem',
        objectFit: 'cover',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: 0,
        },
    },
    profileMedia: {
        maxHeight: '20rem',
        margin: 'auto',
    },
} as const;
