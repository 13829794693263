import React from 'react';
import { Grid } from '@mui/material';
import { GridContainer } from '../GeotabGridComponents';
import { CollapsibleQuestionAnswer } from '../CollapsibleQuestionAnswer';
import { Heading } from '@web-for-marketing/react-ui';
import { AccordionList } from '@models/section/accordionListSection';

const classes = {
    titleContainer: {
        padding: 0,
    },
    title: {
        margin: 0,
        padding: '2rem',
    },
};

interface ListCategoryItemsProps {
    title: string;
    zIndex: number;
    items: AccordionList[];
    headerSection: boolean;
}

export function ListCategoryItems({ items = [], zIndex, title, headerSection }: ListCategoryItemsProps): JSX.Element {
    return (
        <GridContainer style={{ zIndex: zIndex }}>
            {title ? (
                <Grid xs={12} item key='categoryItemsHeader' css={classes.titleContainer}>
                    <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} color='primary' css={classes.title}>
                        {title}
                    </Heading>
                </Grid>
            ) : null}
            {items.map((item, index) => (
                <CollapsibleQuestionAnswer
                    key={index}
                    id={`${item.name.split(' ').join('-')}-${index}`}
                    showTopBorder={index > 0}
                    question={item.name}
                    answer={item.description}
                />
            ))}
        </GridContainer>
    );
}
