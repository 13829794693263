import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { LazyLoadContainer, Text, Heading, Button } from '@web-for-marketing/react-ui';
import { multistepSectionStyles as classes } from './styleObjects/MultistepSectionStyles';
import { MultistepSectionData } from '@models/section/multiStepSection';
import { Picture } from '@components/Picture';
import { CMSLink } from '@components/CMSLink';
import { RichTextContent } from '@components/RichTextContent';
import { ParseAbbreviations } from '@components/ParseAbbreviations';
import { SectionContainer } from './SectionContainer';

interface MultistepSectionProps {
    sectionData: MultistepSectionData;
    lazyLoad: boolean;
    headerSection: boolean;
}
export function MultistepSection({ sectionData, lazyLoad, headerSection }: MultistepSectionProps): JSX.Element {
    const [activeStep, setActiveStep] = useState(1);
    const maxSteps = sectionData.cards.length;
    const stepNavbar = Array.from(Array(maxSteps).keys());
    const { title, description } = sectionData;

    function handleStepChange(step: number): void {
        setActiveStep(step);
    }

    if (activeStep > maxSteps) {
        handleStepChange(1);
    }

    return (
        <SectionContainer>
            <Grid container>
                <Grid item sm={12} md={5} css={classes.mainContentContainer}>
                    {title ? (
                        <Heading
                            variant='h2'
                            component={headerSection ? 'h1' : 'h2'}
                            color='primary'
                            css={classes.mainTitle}
                        >
                            {title}
                        </Heading>
                    ) : null}
                    {description ? (
                        <Text variant='h3' weight='regular' color='primary'>
                            {description}
                        </Text>
                    ) : null}
                </Grid>
                <Grid item sm={12} md={2} css={classes.stepBarContainer}>
                    <Grid css={classes.stepBarWrapper}>
                        {stepNavbar.map((index) => {
                            return (
                                <Button
                                    key={index}
                                    variant='contained'
                                    css={[
                                        classes.stepDot,
                                        activeStep === index + 1 ? classes.currentStepDot : classes.regularStepDot,
                                    ]}
                                    onClick={() => {
                                        handleStepChange(index + 1);
                                    }}
                                >
                                    <Text variant='h2' color='secondary' css={classes.dotNumber}>
                                        {index + 1}
                                    </Text>
                                </Button>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item sm={12} md={5} css={classes.multistepCardContainer}>
                    {sectionData.cards
                        ? sectionData.cards.map((card, index) => {
                            return activeStep - 1 === index ? (
                                <LazyLoadContainer
                                    component={Grid}
                                    lazyload={lazyLoad}
                                    key={`${JSON.stringify(card)}-${index}`}
                                >
                                    <Picture
                                        imagePath={card.imagePath}
                                        alt={card.imageAlt}
                                        css={classes.cardsImage}
                                        lazyLoad={lazyLoad}
                                        aspectRatio={card.imageAspectRatio}
                                    />
                                    <Text variant='h3' weight='regular' color='primary' css={classes.cardTitle}>
                                        {card.cardTitle}
                                    </Text>
                                    <RichTextContent
                                        variant='body1'
                                        css={classes.cardDescription}
                                        color='primary'
                                        content={
                                            ParseAbbreviations({
                                                content: card.description,
                                                stringFormat: true,
                                            }) ?? ''
                                        }
                                    />
                                    {card.linkText ? (
                                        <CMSLink
                                            target={card.linkTarget ? '_blank' : '_self'}
                                            href={card.linkPath}
                                            type='button'
                                            variant='text'
                                        >
                                            {card.linkText}
                                        </CMSLink>
                                    ) : null}
                                </LazyLoadContainer>
                            ) : null;
                        })
                        : null}
                </Grid>
            </Grid>
        </SectionContainer>
    );
}
