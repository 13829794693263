import React from 'react';

export function CoachingIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='45'
            height='44'
            viewBox='0 0 45 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g id='book, guide, info, faq'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.83398 9.1665C7.83398 6.12894 10.2964 3.6665 13.334 3.6665H35.334C36.3465 3.6665 37.1673 4.48732 37.1673 5.49984V40.3332H13.334C10.2964 40.3332 7.83398 37.8707 7.83398 34.8332V9.1665ZM11.5007 34.8332C11.5007 35.8457 12.3215 36.6665 13.334 36.6665H33.5007V32.9998H13.334C12.3215 32.9998 11.5007 33.8206 11.5007 34.8332ZM33.5007 29.3332V7.33317H13.334C12.3215 7.33317 11.5007 8.15398 11.5007 9.1665V29.6461C12.0741 29.4435 12.6912 29.3332 13.334 29.3332H33.5007ZM15.1673 12.8332C15.1673 11.8206 15.9881 10.9998 17.0007 10.9998H28.0007C29.0132 10.9998 29.834 11.8206 29.834 12.8332C29.834 13.8457 29.0132 14.6665 28.0007 14.6665H17.0007C15.9881 14.6665 15.1673 13.8457 15.1673 12.8332ZM15.1673 20.1665C15.1673 19.154 15.9881 18.3332 17.0007 18.3332H22.5007C23.5132 18.3332 24.334 19.154 24.334 20.1665C24.334 21.179 23.5132 21.9998 22.5007 21.9998H17.0007C15.9881 21.9998 15.1673 21.179 15.1673 20.1665Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
