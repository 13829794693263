import { getRgbaColor, breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const shortStackThreeColumnCardStyles = {
    pictureContainer: {
        width: '25%',
        maxHeight: '100%',
        minWidth: '6rem',
        height: 'auto',
        paddingLeft: '1rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '40%',
            maxWidth: '12rem',
        },
    },
    media: {
        objectFit: 'fill',
    },
    card: {
        display: 'flex',
        padding: '3rem 0 3rem 3rem',
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        borderRadius: 10,
        margin: '0 auto',
        border: 'solid 1px #DFE3EA',
        justifyContent: 'space-between',
        boxShadow: `0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}`,
        alignItems: 'center',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '0 auto',
            maxWidth: 450,
            '&:hover': {
                transform: 'none',
            },
        },
    },
    cardParentContainer: {
        flexWrap: 'wrap !important' as 'wrap',
        alignContent: 'center',
    },
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        margin: '0 auto 3rem',
    },
    cardContainer: {
        minWidth: 200,
        boxSizing: 'border-box',
        marginBottom: '4rem',
    },
    linkText: {
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            bottom: '1.5rem',
        },
    },
    bodyText: {
        paddingBottom: '9.4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingBottom: '5.2rem',
        },
    },
    noButtonBody: {
        paddingBottom: '4rem',
    },
    verticalContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 3,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '4rem',
        },
    },
    cardTitle: {
        fontSize: '3.2rem',
        fontWeight: '500',
    },
    cardBody: {
        fontWeight: '400',
        color: v2Colors.text.primary,
    },
    cardDivider: {
        border: `2px dashed ${v2Colors.core.innovation}`,
        margin: '1.6rem 0',
        width: '40px',
    },
    mobileDirection: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexDirection: 'row',
        },
    },
} as const;
