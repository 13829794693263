import React from 'react';
import { CollapsibleListCategory } from '../AccordionLists/CollapsibleListCategory';
import { GridContainer } from '../GeotabGridComponents';
import { Divider, Grid } from '@mui/material';
import { getFAQStructuredDataScript } from '@helpers/faqScriptHelper';
import { ScriptHelmet } from '@components/ScriptHelmet';
import { Heading } from '@web-for-marketing/react-ui';
import { AccordionList } from '@models/section/accordionListSection';

interface AccordionListMobileProps {
    title: string;
    lists: AccordionList[];
    categories: string[];
    zIndex: number;
    enableStructuredData: boolean;
    allFilter: string;
    loadMoreText: string;
}

export function AccordionListMobile({
    lists = [],
    categories = [],
    zIndex,
    enableStructuredData,
    title,
    allFilter,
    loadMoreText,
}: AccordionListMobileProps): JSX.Element {
    const allListFilterCategory = allFilter;

    function getListsForCategory(category: string, lists: AccordionList[]): AccordionList[] {
        if (category === allListFilterCategory) {
            return lists;
        } else {
            return lists.filter((list) => list.category === category);
        }
    }

    return (
        <>
            {enableStructuredData ? <ScriptHelmet script={getFAQStructuredDataScript(lists)} /> : null}
            <GridContainer style={{ zIndex: zIndex }} data-testid='accordion-list-mobile-grid-container'>
                {title ? (
                    <Grid container item xs={12}>
                        <Heading variant='h2' color='primary'>
                            {title}
                        </Heading>
                    </Grid>
                ) : null}
                {Array.isArray(categories)
                    ? categories.map((category, index) => (
                        <CollapsibleListCategory
                            key={`mobile-${category}-${index}`}
                            category={category}
                            loadMoreText={loadMoreText}
                            items={getListsForCategory(category, lists)}
                            id={`mobile-${category.split(' ').join('-')}-${index}`}
                        />
                    ))
                    : null}
            </GridContainer>
            <Divider light />
        </>
    );
}
