import React from 'react';

export function FacebookIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 26 26'
            width='20'
            height='20'
            color='#fff'
            {...props}
        >
            <path
                className='facebook-icon'
                fill='currentColor'
                d='M21.49,2.68v4H19.13a2.2,2.2,0,0,0-1.74.54,2.48,2.48,0,0,0-.45,1.62v2.84h4.4l-.59,4.45H16.94V27.5h-4.6V16.1H8.51V11.65h3.83V8.37A5.82,5.82,0,0,1,13.9,4,5.66,5.66,0,0,1,18.06,2.5,24.88,24.88,0,0,1,21.49,2.68Z'
            />
        </svg>
    );
}
