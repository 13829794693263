import { MarketplaceSolutionsSectionDataFilters } from '@models/section/marketplaceSolutionsSection';

const filterMapper: Record<string, string> = {
    region: 'localRegionId',
    category: 'filterParameter=1&filterSlug',
    industry: 'filterParameter=3&filterSlug',
    solutionTiers: 'solutionTiers',
    sortBy: 'sortBy',
} satisfies Record<keyof MarketplaceSolutionsSectionDataFilters, string>;

export const getAPIcall = (filters?: MarketplaceSolutionsSectionDataFilters): string => {
    let baseURL = '/api/mp_proxy/solutions/public-view-solutions';
    if (filters) {
        baseURL = `${baseURL}?`;
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                if (Array.isArray(value)) {
                    if (value.length) {
                        baseURL = `${baseURL}${filterMapper[key]}=${value?.toString()};`;
                    }
                } else {
                    baseURL = `${baseURL}${filterMapper[key]}=${value}&`;
                }
            }
        }
        baseURL = baseURL.slice(0, -1);
    }
    return baseURL;
};
