import { SectionBase } from '@models/section';

export type CapterraBannerSection = SectionBase<38, { capterraBanner: CapterraBannerSectionData }>;

export type CapterraBannerContent = {
    rating: number;
    quote: string;
    authorName: string;
    authorRole: string;
    industry: string;
};

export interface CapterraBannerSectionData {
    overallRating: number;
    theme: CapterraBannerTheme;
    testimonials: CapterraBannerContent[];
}

export enum CapterraBannerTheme {
    Light = 'light',
    Dark = 'dark',
}
