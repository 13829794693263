import React, { useState, useEffect } from 'react';
import { Divider } from '@mui/material';
import { ListCategories } from '../AccordionLists/ListCategories';
import { ListCategoryItems } from '../AccordionLists/ListCategoryItems';
import { getFAQStructuredDataScript } from '@helpers/faqScriptHelper';
import { ScriptHelmet } from '@components/ScriptHelmet';
import { AccordionList } from '@models/section/accordionListSection';

interface AccordionListDesktopProps {
    lists: AccordionList[];
    title: string;
    categories: string[];
    disableFilter: boolean;
    zIndex: number;
    allFilter: string;
    enableStructuredData: boolean;
    headerSection: boolean;
}

export function AccordionListDesktop({
    lists,
    categories,
    disableFilter,
    zIndex,
    enableStructuredData,
    title,
    allFilter,
    headerSection,
}: AccordionListDesktopProps): JSX.Element {
    const allListsCategory = allFilter;
    const [selectedCategory, setSelectedCategory] = useState(allListsCategory);
    const [filteredLists, setFilteredLists] = useState(lists);

    useEffect(() => {
        setFilteredLists(lists);
    }, [lists]);

    function onCategorySelected(category: string): void {
        setSelectedCategory(category);
        if (category === allListsCategory) {
            setFilteredLists(lists);
        } else {
            setFilteredLists(lists.filter((list) => list.category === category));
        }
    }

    return (
        <>
            {enableStructuredData ? <ScriptHelmet script={getFAQStructuredDataScript(filteredLists)} /> : null}
            {!disableFilter ? (
                <ListCategories
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onCategorySelected={onCategorySelected}
                    zIndex={zIndex}
                />
            ) : null}
            <Divider light />
            <ListCategoryItems
                title={!disableFilter ? title || selectedCategory : title}
                items={filteredLists}
                zIndex={zIndex}
                headerSection={headerSection}
            />
        </>
    );
}
