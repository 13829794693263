import React, { useState } from 'react';
import { Grid, Collapse, Tooltip } from '@mui/material';
import { generalFormStyle } from './EditorFormComponents/styleObjects/GeneralFormStyle';
import { RotatingPlus } from './IconComponents/RotatingPlus';
import { keyIsEnter } from '@helpers/keyPressEventHelper';
import { v2Colors } from '@web-for-marketing/react-ui';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { Heading } from '@web-for-marketing/react-ui';
import { RichTextContent } from './RichTextContent';
import { ClassNames } from '@emotion/react';

const classes = {
    ...generalFormStyle,
    question: {
        maxWidth: 'calc(100% - 5rem)',
    },
    noTopBoarder: {
        borderTop: 'none !important',
    },
    answerBottomSpacing: {
        marginBottom: '2rem',

        '& > p': {
            paddingBottom: '0rem !important',
        },
    },
    deleteListItemButton: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        color: v2Colors.core.silver,
        cursor: 'pointer',
        padding: '0.6rem',
    },
} as const;

interface CollapsibleQuestionAnswerProps {
    question: string;
    answer: string;
    id: string;
    showTopBorder?: boolean;
}

export function CollapsibleQuestionAnswer({
    question,
    answer,
    id,
    showTopBorder = true,
}: CollapsibleQuestionAnswerProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile] = useAtom(isMobileAtom);

    function toggleIsOpen(): void {
        setIsOpen(!isOpen);
    }

    return (
        <Grid
            container
            key={id}
            direction='column'
            css={[classes.collapseParentContainer, !showTopBorder && classes.noTopBoarder]}
        >
            <Grid
                style={isOpen ? { paddingBottom: '1rem' } : undefined}
                container
                direction='row'
                justifyContent='space-between'
                css={classes.collapseSectionsWide}
                aria-controls={id}
                role='button'
                aria-label={isOpen ? `Hide ${question}` : `Show ${question}`}
                aria-expanded={isOpen}
                onClick={toggleIsOpen}
                tabIndex={0}
                onKeyDown={(event) => {
                    keyIsEnter(event) && toggleIsOpen();
                }}
            >
                <Heading variant='h4' component='h2' color='primary' css={classes.question}>
                    {question}
                </Heading>
                {!isMobile ? (
                    <ClassNames>
                        {({ css }) => (
                            <Tooltip
                                title={isOpen ? `Hide ${question}` : `Show ${question}`}
                                classes={{ tooltip: css(classes.tooltip) }}
                            >
                                <div aria-hidden='true' css={classes.deleteListItemButton}>
                                    <RotatingPlus open={isOpen} />
                                </div>
                            </Tooltip>
                        )}
                    </ClassNames>
                ) : null}
            </Grid>
            <div id={id}>
                <Collapse
                    in={isOpen}
                    component='div'
                    css={[classes.collapseContentWide, isOpen ? classes.collapseExpanded : classes.collapseContracted]}
                    timeout={200}
                >
                    <RichTextContent content={answer} css={classes.answerBottomSpacing} />
                </Collapse>
            </div>
        </Grid>
    );
}
