import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

export function ScriptHelmet({ script }) {
    return <Helmet>{script ? <script type={script.type}>{script.body}</script> : null}</Helmet>;
}

ScriptHelmet.defaultProps = {
    script: null,
};

ScriptHelmet.propTypes = {
    script: PropTypes.shape({
        type: PropTypes.string,
        body: PropTypes.string,
    }),
};
