import { overlays, breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const ppcFormHeaderStyles = {
    sectionContainer: {
        paddingBottom: '4rem',
    },
    nextSectionLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '7rem',
        height: '7rem',
        padding: 0,
    },
    nextSectionLinkDesktop: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    customFormContainerMobile: {
        display: 'none',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
        },
    },
    customFormContainerDesktop: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    leftContent: {
        display: 'flex',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
    headingContainer: {
        maxWidth: '70rem',
        color: v2Colors.text.inversePrimary,
    },
    headingContent: {
        marginBottom: '3rem',
    },
    formContainer: {
        padding: '3rem 3rem 5rem 3rem',
        background: v2Colors.core.snow,
        borderRadius: '1rem',
    },
    configureForm: {
        height: '75rem',
        backgroundColor: overlays.primary,
        textAlign: 'center',
        color: v2Colors.text.inversePrimary,
        border: `4px dashed ${v2Colors.background.white}`,
        borderRadius: '44px',
    },
    defaultBackground: {
        background: 'linear-gradient(90deg, rgba(11, 32, 50, 0.95) 7.29%, rgba(60, 81, 100, 0) 100%)',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            background: `linear-gradient(${overlays.primary}, ${overlays.primary})`,
        },
    },
    backgroundWithImage: (imageUrl: string) => ({
        background: imageUrl
            ? `linear-gradient(90deg, rgba(11, 32, 50, 0.95) 7.29%, rgba(60, 81, 100, 0) 100%), url('${imageUrl}') center / cover no-repeat`
            : undefined,
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            background: `linear-gradient(${overlays.primary}, ${overlays.primary}), url('${imageUrl}') center / cover no-repeat`,
        },
    }),
    testimonial: {
        border: `1px solid ${v2Colors.background.white}`,
        padding: '2rem 3rem',
        borderRadius: '5px',
        width: 'fit-content',
    },
    testimonialImageDesktop: {
        marginRight: '2.5rem',
        width: '10rem',
        height: '10rem',
        borderRadius: '80px',
        objectFit: 'cover',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'none',
        },
    },
    testimonialImageMobile: {
        display: 'none',
        marginRight: '0.8rem',
        width: '2.4rem',
        height: '2.4rem',
        borderRadius: '80px',
        objectFit: 'cover',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'block',
        },
    },
    authorContainer: {
        marginTop: '0.8rem',
    },
} as const;
