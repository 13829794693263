import React from 'react';

export function IntegrationIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='45'
            height='44'
            viewBox='0 0 42 42'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g id='Vector'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.1852 1.75463L27.4569 11.0263C31.9941 15.5636 31.9942 22.9199 27.4569 27.4571C22.9197 31.9943 15.5634 31.9943 11.0262 27.4571L1.7546 18.1856L18.1852 1.75463ZM18.1852 7.15436L7.15429 18.1855L13.726 24.7572C16.7722 27.8034 21.711 27.8034 24.7571 24.7572C27.8032 21.7111 27.8032 16.7724 24.7571 13.7261L18.1852 7.15436Z'
                    fill='#0078D3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0.559157 7.95271C1.3047 7.20716 2.51346 7.20716 3.25901 7.95271L8.22673 12.9205C8.97227 13.666 8.97227 14.8748 8.22673 15.6203C7.48118 16.3659 6.27242 16.3659 5.52688 15.6203L0.559157 10.6526C-0.186386 9.90703 -0.186386 8.69826 0.559157 7.95271Z'
                    fill='#0078D3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.95289 0.55916C8.69843 -0.186387 9.9072 -0.186387 10.6527 0.55916L15.6205 5.52691C16.366 6.27245 16.366 7.48122 15.6205 8.22677C14.8749 8.97232 13.6662 8.97232 12.9206 8.22677L7.95289 3.25902C7.20735 2.51348 7.20735 1.30471 7.95289 0.55916Z'
                    fill='#0078D3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24.7571 24.7572C25.5026 24.0117 26.7114 24.0117 27.4569 24.7572L41.4408 38.741C42.1864 39.4865 42.1864 40.6953 41.4409 41.4408C40.6953 42.1864 39.4866 42.1864 38.741 41.4409L24.7571 27.4571C24.0116 26.7115 24.0116 25.5028 24.7571 24.7572Z'
                    fill='#0078D3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.6479 0.991964C22.3935 1.73751 22.3935 2.94628 21.6479 3.69183L3.69218 21.6476C2.94664 22.3932 1.73788 22.3932 0.992336 21.6476C0.246795 20.9021 0.246797 19.6933 0.992341 18.9477L18.9481 0.991959C19.6936 0.246414 20.9024 0.246416 21.6479 0.991964Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
