import React, { useMemo } from 'react';
import { ImageTitleLinkCards } from './ImageTitleLinkCards';
import { CustomizableGalleryCards } from './CustomizableGalleryCards';
import { CustomizableImageGalleryContent } from '@models/section/customizableImageGallerySection';
import { SectionAnimation } from '@models/section';

type CustomizableImageGalleryProps = CustomizableImageGalleryContent & {
    animations: SectionAnimation;
    zIndex: number;
    lazyLoad: boolean;
    headerSection: boolean;
};

export function CustomizableImageGallery({
    images,
    title,
    animations,
    zIndex,
    size,
    align,
    lazyLoad,
    type,
    background,
    headerSection,
}: CustomizableImageGalleryProps): JSX.Element | null {
    const renderedBoxes: JSX.Element | null = useMemo(() => {
        switch (type) {
            case 'image-title-link-cards':
                return (
                    <ImageTitleLinkCards
                        cards={images}
                        title={title}
                        background={background}
                        align={align}
                        size={size}
                        zIndex={zIndex}
                        lazyLoad={lazyLoad}
                        animations={animations}
                        headerSection={headerSection}
                    />
                );
            case 'customizable-image-gallery':
                return (
                    <CustomizableGalleryCards
                        cardProps={{
                            images,
                            title,
                            animations,
                            zIndex,
                            size,
                            align,
                            lazyLoad,
                            background,
                            headerSection,
                        }}
                    />
                );
            default:
                return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, images]);

    return renderedBoxes;
}

CustomizableImageGallery.defaultProps = {
    align: 'center',
    size: 2,
    images: [
        { image: { imagePath: '', imageAlt: '' }, linkPath: '/#', linkEnabled: true, linkTarget: true },
        { image: { imagePath: '', imageAlt: '' }, linkPath: '/#', linkEnabled: true, linkTarget: true },
        { image: { imagePath: '', imageAlt: '' }, linkPath: '/#', linkEnabled: true, linkTarget: true },
    ],
    animations: {
        animationsEnabled: false,
    },
    type: 'customizable-image-gallery',
};
