import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const capterraTestimonialStyles = {
    darkBackground: {
        backgroundColor: v2Colors.background.darker,
    },
    lightBackground: {
        backgroundColor: v2Colors.background.lighter,
    },
    cardLogo: {
        marginBottom: '1.6rem',
    },
    overallRating: {
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'none',
        },
    },
    testimonialQuote: {
        width: '100%',
        marginTop: '1.6rem',
        marginBottom: '1.6rem',
        height: '10rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '4',
        flex: '1',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            height: '6rem',
            WebkitLineClamp: '3',
        },
    },
    authorContainer: {
        width: '100%',
    },
    pagination: {
        display: 'block',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'none',
        },
    },
    paginationMobile: {
        display: 'none',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'block',
        },
    },
    dotsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '3.2rem',
        gap: '1.6rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            gap: '0.1rem',
            justifyContent: 'flex-start',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            marginTop: '2.4rem',
        },
    },
    dotButton: {
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        padding: 0,
    },
    dot: (darkTheme: boolean) => ({
        backgroundColor: 'transparent',
        height: '1rem',
        width: '1rem',
        borderRadius: 6,
        margin: 3,
        border: `1px solid ${darkTheme ? v2Colors.border.inversePrimary : v2Colors.border.activePrimary}`,
    }),
    dotActive: (darkTheme: boolean) => ({
        backgroundColor: darkTheme ? v2Colors.action.white : v2Colors.core.innovation,
    }),
} as const;
