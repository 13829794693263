import { v2Colors, getRgbaColor, breakpoints } from '@web-for-marketing/react-ui';

export const pageCoveringPopOutStyle = {
    shareSocialIcon: {
        margin: '0.5rem 0.5rem',
        padding: '0.5rem',
        '& > svg': {
            width: '3.6rem',
            height: '3.6rem',
            color: v2Colors.core.innovation,
            verticalAlign: 'bottom',
        },
        '&:hover > svg': {
            color: v2Colors.core.geotab,
        },
        '&:hover': {
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
        },
        '&:focus > svg': {
            color: v2Colors.core.geotab,
        },
        '&:focus': {
            backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
        },
    },
    transparentBackground: {
        background: `${getRgbaColor(v2Colors.core.snow, 0.9)} 0% 0% no-repeat padding-box`,
        backdropFilter: 'blur(1rem)',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            background: 'none',
            backdropFilter: 'none',
        },
    },
    nextPrevOuterContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            position: 'static',
        },
    },
    innerContainerOverflow: {
        overflowY: 'auto',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            height: 'auto !important',
        },
    },
    nextPrevContainer: {
        padding: '2rem 3.6rem',
        flexDirection: 'row',
        '& > div': {
            display: 'flex',
            margin: 'unset',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            position: 'static',
            bottom: 0,
            left: 0,
            transform: 'none',
            padding: '0 3.6rem 2rem',
            '& > *': {
                display: 'flex',
                width: '100%',
            },
            '& > *:first-child': {
                marginBottom: '2rem',
            },
            '& > * > *': {
                width: '100%',
            },
        },
    },
    popOutContainer: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        background: 'none',
        zIndex: 901,
        top: 0,
        left: 0,
        margin: 0,
        borderRadius: 0,
        maxWidth: 'initial !important',
        maxHeight: 'initial !important',
        display: 'block',
        visibility: 'hidden',
        opacity: 0.4,
        transition: 'opacity 100ms ease, visibility 100ms ease',
    },
    openPopOut: {
        overflow: 'auto',
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 200ms ease, visibility 200ms ease',
    },
    profileMedia: {
        margin: '0 auto',
        maxWidth: 400,
        minWidth: 200,
        borderRadius: 10,
    },
    leftAlignChildren: {
        '& *': {
            textAlign: 'left',
        },
    },
    rightAlignChildren: {
        '& *': {
            textAlign: 'right',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& *': {
                textAlign: 'left',
            },
        },
    },
    socialContainer: {
        justifyContent: 'center',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            justifyContent: 'flex-start',
        },
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    deleteButton: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            position: 'static',
            top: 'unset',
            right: 'unset',
            margin: '1.5rem 1.5rem 0',
            float: 'right',
        },
    },
    mobileTransparentBackground: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            background: `${getRgbaColor(v2Colors.core.snow, 0.9)} 0% 0% no-repeat padding-box`,
            backdropFilter: 'blur(1rem)',
        },
    },
} as const;
