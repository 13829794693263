import { useMemo } from 'react';

interface useUtmUrlParamsProps {
    utmSource: string | undefined;
    utmMedium: string | undefined;
    utmCampaign: string | undefined;
}

export function useUtmUrlParams({ utmSource, utmMedium, utmCampaign }: useUtmUrlParamsProps): string {
    const utmUrlParameters = useMemo(() => {
        const utmParameters: string[] = [];

        if (utmSource) {
            utmParameters.push(`utm_source=${utmSource}`);
        }

        if (utmMedium) {
            utmParameters.push(`utm_medium=${utmMedium}`);
        }

        if (utmCampaign) {
            utmParameters.push(`utm_campaign=${utmCampaign}`);
        }

        return utmParameters.length ? `?${utmParameters.join('&')}` : '';
    }, [utmSource, utmMedium, utmCampaign]);

    return utmUrlParameters;
}
