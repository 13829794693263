import { v2Colors } from '@web-for-marketing/react-ui';
import React from 'react';

export function ChevronLeft(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 8 17'
            xmlSpace='preserve'
            width='1em'
            height='1em'
            color={v2Colors.core.slate}
            {...props}
        >
            <path
                fill='currentColor'
                d='M7.48809 1.05055C7.80259 1.32012 7.83901 1.79359 7.56944 2.10809L1.98781 8.61999L7.56944 15.1319C7.83901 15.4464 7.80259 15.9199 7.48809 16.1894C7.1736 16.459 6.70012 16.4226 6.43056 16.1081L0.430558 9.10809C0.189814 8.82722 0.189814 8.41277 0.430558 8.1319L6.43056 1.1319C6.70012 0.817406 7.1736 0.780984 7.48809 1.05055Z'
            />
        </svg>
    );
}
