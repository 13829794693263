import { v2Colors, breakpoints } from '@web-for-marketing/react-ui';

export const customizableImageGalleryStyle = {
    titles: {
        color: v2Colors.core.geotab,
        textAlign: 'center',
        maxWidth: '72rem',
        margin: '0 auto 3rem',
    },
    galleryContainer: {
        padding: '0 !important',
    },
    linkContainer: {
        height: '100%',
        width: '100%',
    },
    cardContainer: {
        padding: '2.7rem 2%',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '1rem 2rem',
            padding: 0,
            maxWidth: 150,
            '&:first-child > div': {
                margin: '0 auto',
                float: 'unset !important' as 'unset',
            },
            '&:last-child > div': {
                margin: '0 auto',
                float: 'unset !important' as 'unset',
            },
        },
    },
    media: {
        height: 'auto',
        width: '100%',
    },
    smallImage: {
        height: 'auto',
        width: '100%',
        objectFit: 'contain',
        maxHeight: '80px',
    },
    expandedContainer: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& img': {
                margin: '3rem 0 !important',
            },
        },
    },
} as const;
