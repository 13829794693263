import React from 'react';

export function LightQuotationMark(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='90'
            height='68'
            viewBox='0 0 90 68'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M20.4309 68C14.7412 68 9.91361 66.0177 5.94809 62.0532C1.98258 58.0887 -0.000183105 51.711 -0.000183105 42.9201C-0.000183105 31.8885 3.10327 22.4081 9.31016 14.4791C15.5171 6.37769 24.0515 1.55133 34.9136 0L35.9481 8.27376C31.8102 8.79087 27.6722 10.4284 23.5343 13.1863C19.5688 15.7719 17.586 18.9607 17.586 22.7528C17.586 24.6489 18.0171 25.9417 18.8791 26.6312C19.9136 27.3207 21.4653 28.0101 23.5343 28.6996C34.5688 31.8023 40.086 38.18 40.086 47.8327C40.086 53.5209 38.2757 58.3473 34.655 62.3118C31.2067 66.1039 26.4653 68 20.4309 68ZM70.3446 68C64.655 68 59.8274 66.0177 55.8619 62.0532C51.8964 57.9163 49.9136 51.4525 49.9136 42.6616C49.9136 31.8023 53.0171 22.4081 59.224 14.4791C65.4309 6.37769 73.9653 1.55133 84.8274 0L85.8619 8.01521C81.5515 8.70469 77.4136 10.3422 73.4481 12.9278C69.4826 15.5133 67.4998 18.7022 67.4998 22.4943C67.4998 24.3904 67.9308 25.6831 68.7929 26.3726C69.8274 27.0621 71.3791 27.7516 73.4481 28.4411C84.4826 31.5437 89.9998 38.0076 89.9998 47.8327C89.9998 53.5209 88.1895 58.3473 84.5688 62.3118C81.1205 66.1039 76.3791 68 70.3446 68Z'
                fill='white'
            />
        </svg>
    );
}
