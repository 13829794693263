import React from 'react';

export function StorageIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='45'
            height='44'
            viewBox='0 0 45 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <g id='archive, box, inbox, file'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.16602 7.33333C4.16602 6.32081 4.98683 5.5 5.99935 5.5H38.9993C40.0119 5.5 40.8327 6.32081 40.8327 7.33333V14.6667C40.8327 15.6792 40.0119 16.5 38.9993 16.5V36.6667C38.9993 37.6792 38.1785 38.5 37.166 38.5H7.83268C6.82016 38.5 5.99935 37.6792 5.99935 36.6667V16.5C4.98683 16.5 4.16602 15.6792 4.16602 14.6667V7.33333ZM9.66602 16.5V34.8333H35.3327V16.5H9.66602ZM37.166 12.8333V9.16667H7.83268V12.8333H37.166ZM16.9993 22C16.9993 20.9875 17.8202 20.1667 18.8327 20.1667H26.166C27.1785 20.1667 27.9993 20.9875 27.9993 22C27.9993 23.0125 27.1785 23.8333 26.166 23.8333H18.8327C17.8202 23.8333 16.9993 23.0125 16.9993 22Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
