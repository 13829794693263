import { spacingAsSplitValues } from '@components/EditorFormComponents/helpers/ColumnFormTypes';
import { ContentSpacing } from '@models/section';

export const splitPaddingValues = (padding: ContentSpacing): { paddingTop: number; paddingBottom: number } => {
    if (padding in spacingAsSplitValues) {
        return { ...spacingAsSplitValues[padding] };
    } else {
        return { paddingTop: 8, paddingBottom: 8 };
    }
};

export function getContainerStyle(
    spacing: ContentSpacing,
    isMobile: boolean,
    zIndex: number
): { paddingTop: string; paddingBottom: string; zIndex: number } {
    const padding = splitPaddingValues(spacing);

    if (isMobile && (padding.paddingTop <= 8 || padding.paddingBottom <= 8)) {
        padding.paddingTop /= 2;
        padding.paddingBottom /= 2;
    }

    return { zIndex, paddingTop: `${padding.paddingTop}rem`, paddingBottom: `${padding.paddingBottom}rem` };
}
